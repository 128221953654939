@charset "UTF-8";
.mdc-list-item__graphic.nty-list-graphic-icon {
  margin-right: 16px;
}

.mdc-icon-button {
  text-align: center;
}

.svg-inline--fa.fa-thumbtack.nty-pale, .svg-inline--fa.fa-users.nty-pale {
  font-size: 10px;
  margin: 0 4px 1px;
  opacity: 0.4;
}
.svg-inline--fa.fa-angle-right.nty-icon-button, .svg-inline--fa.fa-bars.nty-icon-button, .svg-inline--fa.fa-compress-alt.nty-icon-button, .svg-inline--fa.fa-ellipsis-h.nty-icon-button, .svg-inline--fa.fa-ellipsis-v.nty-icon-button, .svg-inline--fa.fa-expand-alt.nty-icon-button, .svg-inline--fa.fa-folder-open.nty-icon-button, .svg-inline--fa.fa-trash.nty-icon-button {
  font-size: 0.75em;
  vertical-align: 0;
}

.nty-note-markdown {
  overflow-wrap: break-word;
}
.nty-note-markdown p {
  font-size: 16px;
}
.nty-note-markdown ul,
.nty-note-markdown ol {
  font-size: 16px;
}
.nty-note-markdown hr {
  border-color: #eceff1;
}
.nty-note-markdown .task-list-item {
  list-style-type: none;
  margin-left: -18px;
}
.nty-note-markdown h1 {
  font-size: 32px;
}
.nty-note-markdown h2 {
  font-size: 24px;
}
.nty-note-markdown h3 {
  font-size: 20px;
}
.nty-note-markdown h4 {
  font-size: 16px;
}
.nty-note-markdown h5 {
  font-size: 14px;
}
.nty-note-markdown h6 {
  font-size: 13px;
}
.nty-note-markdown h1,
.nty-note-markdown h2,
.nty-note-markdown h3,
.nty-note-markdown h4,
.nty-note-markdown h5,
.nty-note-markdown h6 {
  border-bottom: 1px solid #eceff1;
  font-weight: 500;
  line-height: 1.25;
}
.nty-note-markdown > code,
.nty-note-markdown :not(.highlight) code {
  background-color: #eceff1;
  border-radius: 4px;
  overflow: auto;
  padding: 2px 6px;
}
.nty-note-markdown pre {
  border-radius: 4px;
  font-size: 14px;
  overflow: auto;
  padding: 16px;
}
.nty-note-markdown table {
  border-collapse: collapse;
  border-spacing: 0;
  display: block;
  font-size: 16px;
  margin-bottom: 16px;
  overflow: auto;
  width: 100%;
}
.nty-note-markdown table td,
.nty-note-markdown table th {
  border: 1px solid #cfd8dc;
  padding: 8px 14px;
}
.nty-note-markdown table thead {
  text-align: center;
}
.nty-note-markdown table tr:nth-child(2n) {
  background-color: #eceff1;
}
.nty-note-markdown blockquote {
  border-left: 4px solid #cfd8dc;
  color: #607d8b;
  font-style: normal;
  margin: 0;
  padding: 0 16px;
}
.nty-note-markdown blockquote::before {
  content: "";
}
.nty-note-markdown blockquote::after {
  content: "";
  margin-left: 0;
}
.nty-note-markdown .highlight {
  background-color: #122b3b;
  color: #faf6e4;
}
.nty-note-markdown .highlight table td {
  padding: 5px;
}
.nty-note-markdown .highlight table pre {
  margin: 0;
}
.nty-note-markdown .highlight .gl {
  background-color: #4e5d62;
  color: #dee5e7;
}
.nty-note-markdown .highlight .gp {
  color: #a8e1fe;
  font-weight: bold;
}
.nty-note-markdown .highlight .c,
.nty-note-markdown .highlight .cd,
.nty-note-markdown .highlight .cm,
.nty-note-markdown .highlight .c1,
.nty-note-markdown .highlight .cs {
  color: #6c8b9f;
  font-style: italic;
}
.nty-note-markdown .highlight .cp {
  color: #b2fd6d;
  font-style: italic;
  font-weight: bold;
}
.nty-note-markdown .highlight .err {
  background-color: #c00;
  color: #fefeec;
}
.nty-note-markdown .highlight .gr {
  color: #c00;
  font-style: italic;
  font-weight: bold;
}
.nty-note-markdown .highlight .k,
.nty-note-markdown .highlight .kd,
.nty-note-markdown .highlight .kv {
  color: #f6dd62;
  font-weight: bold;
}
.nty-note-markdown .highlight .o,
.nty-note-markdown .highlight .ow {
  color: #4df4ff;
  font-weight: bold;
}
.nty-note-markdown .highlight .p,
.nty-note-markdown .highlight .pi {
  color: #4df4ff;
}
.nty-note-markdown .highlight .gd {
  color: #c00;
}
.nty-note-markdown .highlight .gi {
  color: #b2fd6d;
}
.nty-note-markdown .highlight .ge {
  font-style: italic;
}
.nty-note-markdown .highlight .gs {
  font-weight: bold;
}
.nty-note-markdown .highlight .gt {
  background-color: #4e5d62;
  color: #dee5e7;
}
.nty-note-markdown .highlight .kc {
  color: #f696db;
  font-weight: bold;
}
.nty-note-markdown .highlight .kn,
.nty-note-markdown .highlight .kp,
.nty-note-markdown .highlight .kr,
.nty-note-markdown .highlight .gh,
.nty-note-markdown .highlight .gu {
  color: #ffb000;
  font-weight: bold;
}
.nty-note-markdown .highlight .kt,
.nty-note-markdown .highlight .no,
.nty-note-markdown .highlight .nc,
.nty-note-markdown .highlight .nd,
.nty-note-markdown .highlight .nn,
.nty-note-markdown .highlight .bp,
.nty-note-markdown .highlight .ne {
  color: #b2fd6d;
  font-weight: bold;
}
.nty-note-markdown .highlight .nl,
.nty-note-markdown .highlight .nt {
  color: #ffb000;
  font-weight: bold;
}
.nty-note-markdown .highlight .m,
.nty-note-markdown .highlight .mf,
.nty-note-markdown .highlight .mh,
.nty-note-markdown .highlight .mi,
.nty-note-markdown .highlight .il,
.nty-note-markdown .highlight .mo,
.nty-note-markdown .highlight .mb,
.nty-note-markdown .highlight .mx,
.nty-note-markdown .highlight .ld,
.nty-note-markdown .highlight .ss {
  color: #f696db;
  font-weight: bold;
}
.nty-note-markdown .highlight .s,
.nty-note-markdown .highlight .sb,
.nty-note-markdown .highlight .sd,
.nty-note-markdown .highlight .s2,
.nty-note-markdown .highlight .sh,
.nty-note-markdown .highlight .sx,
.nty-note-markdown .highlight .sr,
.nty-note-markdown .highlight .s1 {
  color: #fff0a6;
  font-weight: bold;
}
.nty-note-markdown .highlight .se,
.nty-note-markdown .highlight .sc,
.nty-note-markdown .highlight .si {
  color: #4df4ff;
  font-weight: bold;
}
.nty-note-markdown .highlight .nb {
  font-weight: bold;
}
.nty-note-markdown .highlight .ni {
  color: #999;
  font-weight: bold;
}
.nty-note-markdown .highlight .w,
.nty-note-markdown .highlight .go {
  color: #bbb;
}
.nty-note-markdown .highlight .nf,
.nty-note-markdown .highlight .py,
.nty-note-markdown .highlight .na {
  color: #a8e1fe;
}
.nty-note-markdown .highlight .nv,
.nty-note-markdown .highlight .vc,
.nty-note-markdown .highlight .vg,
.nty-note-markdown .highlight .vi {
  color: #a8e1fe;
  font-weight: bold;
}

body {
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  margin: 0;
  min-height: 100vh;
}

a {
  color: #26a69a;
  text-decoration: none;
}

.nty-row-horizontal {
  border-bottom: 1px solid #bdbdbd;
  line-height: 0.1em;
  margin: 24px 0;
  text-align: center;
}
.nty-row-horizontal .nty-row-text {
  background-color: #fff;
  color: #bdbdbd;
  padding: 0 12px;
  position: relative;
  text-align: center;
  width: 100%;
}

.nty-button-oauth {
  background-color: #4285f4;
  background-image: url("../images/btn_sign_in.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  height: 36px;
  line-height: 36px;
  min-width: 170px;
  padding-left: 36px;
  text-align: center;
  text-decoration: none;
}
.nty-button-oauth:hover {
  background-color: #296cdb;
}
.nty-button-oauth.nty-button-oauth--full-width {
  padding: 0;
  width: 100%;
}

.nty-block-buttons {
  align-items: center;
  display: flex;
  justify-content: center;
}
.nty-block-buttons .mdc-button {
  margin: 0 4px;
}

.nty-select {
  appearance: none;
  background-color: rgb(244.8, 244.8, 244.8);
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAMAAACzvE1FAAAADFBMVEUzMzMzMzMzMzMzMzMKAG/3AAAAA3RSTlMAf4C/aSLHAAAAPElEQVR42q3NMQ4AIAgEQTn//2cLdRKppSGzBYwzVXvznNWs8C58CiussPJj8h6NwgorrKRdTvuV9v16Afn0AYFOB7aYAAAAAElFTkSuQmCC");
  background-position: right 8px center;
  background-repeat: no-repeat;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  display: inline-block;
  height: 34px;
  line-height: 20px;
  margin-top: 5px;
  min-height: 34px;
  outline: none;
  padding-right: 24px;
  vertical-align: middle;
}
.nty-select.focus, .nty-select:focus {
  border-color: rgba(0, 0, 0, 0.73);
  outline: none;
}
.nty-select--full-width {
  width: stretch;
}

.nty-tag-depth-0 {
  padding-left: 8px !important;
}

.nty-tag-depth-1 {
  padding-left: 16px !important;
}

.nty-tag-depth-2 {
  padding-left: 24px !important;
}

.nty-tag-depth-3 {
  padding-left: 32px !important;
}

.nty-tag-depth-4 {
  padding-left: 40px !important;
}

.nty-tag-depth-5 {
  padding-left: 48px !important;
}

.nty-tag-depth-6 {
  padding-left: 56px !important;
}

.nty-tag-depth-7 {
  padding-left: 64px !important;
}

.nty-tag-depth-8 {
  padding-left: 72px !important;
}

.nty-tag-depth-9 {
  padding-left: 80px !important;
}

.nty-note-tag {
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  color: #4a4a4a;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 400;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  margin: 0 8px 0 0;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
}
.nty-note-tag[href]:hover, .nty-note-tag[href]:focus {
  background-color: #dcdcdc;
  text-decoration: none;
}

.pagination {
  padding: 12px 0 0;
}
.pagination a,
.pagination .current {
  padding: 0 8px;
}
.pagination .previous_page,
.pagination .next_page {
  padding: 0;
}
.pagination .current {
  font-style: normal;
}

.nty-typography--text-center {
  text-align: center;
}

.mdc-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mdc-list:focus {
  outline: none;
}

.mdc-list-item {
  height: 48px;
}

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__graphic {
  background-color: transparent;
}

.mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item--disabled .mdc-list-item__text {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__text,
.mdc-list-item--disabled .mdc-list-item__primary-text,
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: #2f4456;
  /* @alternate */
  color: var(--mdc-theme-primary, #2f4456);
}
.mdc-list-item--selected .mdc-list-item__graphic,
.mdc-list-item--activated .mdc-list-item__graphic {
  color: #2f4456;
  /* @alternate */
  color: var(--mdc-theme-primary, #2f4456);
}

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.812rem;
}

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 48px;
}
.mdc-list-item:focus {
  outline: none;
}
.mdc-list-item:not(.mdc-list-item--selected):focus::before, .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
}
.mdc-list-item.mdc-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
}
[dir=rtl] .mdc-list-item, .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--icon-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-list--icon-list .mdc-list-item, .mdc-list--icon-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--avatar-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-item, .mdc-list--avatar-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--thumbnail-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 56px;
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-item, .mdc-list--thumbnail-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--image-list .mdc-list-item {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-list--image-list .mdc-list-item, .mdc-list--image-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-list--video-list .mdc-list-item {
  /* @noflip */
  padding-left: 0px;
  /* @noflip */
  padding-right: 16px;
  height: 72px;
}
[dir=rtl] .mdc-list--video-list .mdc-list-item, .mdc-list--video-list .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0px;
}

.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 20px;
  height: 20px;
}
[dir=rtl] .mdc-list--dense .mdc-list-item__graphic, .mdc-list--dense .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list-item__graphic {
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor;
  object-fit: cover;
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-list-item__graphic, .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--icon-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .mdc-list--icon-list .mdc-list-item__graphic, .mdc-list--icon-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-item__graphic, .mdc-list--avatar-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--thumbnail-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-item__graphic, .mdc-list--thumbnail-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--image-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 56px;
  height: 56px;
}
[dir=rtl] .mdc-list--image-list .mdc-list-item__graphic, .mdc-list--image-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-list--video-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 100px;
  height: 56px;
}
[dir=rtl] .mdc-list--video-list .mdc-list-item__graphic, .mdc-list--video-list .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list .mdc-list-item__graphic {
  display: inline-flex;
}

.mdc-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}
.mdc-list-item__meta:not(.material-icons) {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}
.mdc-list-item[dir=rtl] .mdc-list-item__meta, [dir=rtl] .mdc-list-item .mdc-list-item__meta {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}

.mdc-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-list-item__text[for] {
  pointer-events: none;
}

.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list--video-list .mdc-list-item__primary-text, .mdc-list--image-list .mdc-list-item__primary-text, .mdc-list--thumbnail-list .mdc-list-item__primary-text, .mdc-list--avatar-list .mdc-list-item__primary-text, .mdc-list--icon-list .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list--video-list .mdc-list-item__primary-text::before, .mdc-list--image-list .mdc-list-item__primary-text::before, .mdc-list--thumbnail-list .mdc-list-item__primary-text::before, .mdc-list--avatar-list .mdc-list-item__primary-text::before, .mdc-list--icon-list .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list--video-list .mdc-list-item__primary-text::after, .mdc-list--image-list .mdc-list-item__primary-text::after, .mdc-list--thumbnail-list .mdc-list-item__primary-text::after, .mdc-list--avatar-list .mdc-list-item__primary-text::after, .mdc-list--icon-list .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__secondary-text {
  font-size: inherit;
}

.mdc-list--dense .mdc-list-item {
  height: 40px;
}

.mdc-list--two-line .mdc-list-item__text {
  align-self: flex-start;
}

.mdc-list--two-line .mdc-list-item {
  height: 64px;
}
.mdc-list--two-line.mdc-list--video-list .mdc-list-item, .mdc-list--two-line.mdc-list--image-list .mdc-list-item, .mdc-list--two-line.mdc-list--thumbnail-list .mdc-list-item, .mdc-list--two-line.mdc-list--avatar-list .mdc-list-item, .mdc-list--two-line.mdc-list--icon-list .mdc-list-item {
  height: 72px;
}
.mdc-list--two-line.mdc-list--icon-list .mdc-list-item__graphic {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-list--two-line.mdc-list--dense .mdc-list-item,
.mdc-list--avatar-list.mdc-list--dense .mdc-list-item {
  height: 60px;
}

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 36px;
  height: 36px;
}
[dir=rtl] .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic, .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

:not(.mdc-list-item--disabled).mdc-list-item {
  cursor: pointer;
}

a.mdc-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-list-divider--padded {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list-divider--padded, .mdc-list-divider--padded[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list-divider--inset, .mdc-list-divider--inset[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}

.mdc-list-divider--inset.mdc-list-divider--padded {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list-divider--inset.mdc-list-divider--padded, .mdc-list-divider--inset.mdc-list-divider--padded[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}

.mdc-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading, .mdc-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--icon-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading, .mdc-list--icon-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--icon-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--icon-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--avatar-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading, .mdc-list--avatar-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--avatar-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--avatar-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--thumbnail-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--thumbnail-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--image-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 88px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 88px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading, .mdc-list--image-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 88px;
}
.mdc-list--image-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 88px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 104px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 88px;
}
.mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
.mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 32px);
}
[dir=rtl] .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--image-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-list--video-list .mdc-list-divider--inset-leading {
  /* @noflip */
  margin-left: 116px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 116px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading, .mdc-list--video-list .mdc-list-divider--inset-leading[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 116px;
}
.mdc-list--video-list .mdc-list-divider--inset-trailing {
  width: calc(100% - 16px);
}
.mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing {
  /* @noflip */
  margin-left: 116px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 132px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing, .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 116px;
}
.mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--padding {
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 0px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--padding, .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0px;
}
.mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding {
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 16px);
}
[dir=rtl] .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding, .mdc-list--video-list .mdc-list-divider--inset-leading.mdc-list-divider--inset-trailing.mdc-list-divider--inset-padding[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0px;
}

.mdc-list-group .mdc-list {
  padding: 0;
}

.mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

.mdc-evolution-list-item__primary-text {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-evolution-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-evolution-list-item--with-leading-icon .mdc-evolution-list-item__start,
.mdc-evolution-list-item--with-trailing-icon .mdc-evolution-list-item__end {
  background-color: transparent;
}

.mdc-evolution-list-item--with-leading-icon .mdc-evolution-list-item__start,
.mdc-evolution-list-item--with-trailing-icon .mdc-evolution-list-item__end {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-evolution-list-item--disabled .mdc-evolution-list-item__start,
.mdc-evolution-list-item--disabled .mdc-evolution-list-item__content,
.mdc-evolution-list-item--disabled .mdc-evolution-list-item__end {
  opacity: 0.38;
}

.mdc-evolution-list-item--disabled .mdc-evolution-list-item__primary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-evolution-list-item--disabled .mdc-evolution-list-item__secondary-text {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-evolution-list-item--disabled.mdc-evolution-list-item--with-leading-icon .mdc-evolution-list-item__start {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-evolution-list-item--disabled.mdc-evolution-list-item--with-trailing-icon .mdc-evolution-list-item__end {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-evolution-list-item--disabled.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-evolution-list-item--selected .mdc-evolution-list-item__primary-text,
.mdc-evolution-list-item--activated .mdc-evolution-list-item__primary-text {
  color: #2f4456;
  /* @alternate */
  color: var(--mdc-theme-primary, #2f4456);
}
.mdc-evolution-list-item--selected.mdc-evolution-list-item--with-leading-icon .mdc-evolution-list-item__start,
.mdc-evolution-list-item--activated.mdc-evolution-list-item--with-leading-icon .mdc-evolution-list-item__start {
  color: #2f4456;
  /* @alternate */
  color: var(--mdc-theme-primary, #2f4456);
}

.mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

@media screen and (-ms-high-contrast: active) {
  .mdc-evolution-list-divider::after {
    content: "";
    display: block;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: white;
  }
}
.mdc-evolution-list {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
}
.mdc-evolution-list:focus {
  outline: none;
}

.mdc-evolution-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
  align-items: stretch;
  cursor: pointer;
}
.mdc-evolution-list-item:focus {
  outline: none;
}
[dir=rtl] .mdc-evolution-list-item, .mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-evolution-list-item.mdc-evolution-list-item--with-one-line {
  height: 48px;
}
.mdc-evolution-list-item.mdc-evolution-list-item--with-two-lines {
  height: 64px;
}
.mdc-evolution-list-item.mdc-evolution-list-item--with-three-lines {
  height: 88px;
}
.mdc-evolution-list-item.mdc-evolution-list-item--with-one-line .mdc-evolution-list-item__start {
  align-self: center;
  margin-top: 0;
}
.mdc-evolution-list-item.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-evolution-list-item.mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-evolution-list-item.mdc-evolution-list-item--with-one-line .mdc-evolution-list-item__end {
  align-self: center;
  margin-top: 0;
}
.mdc-evolution-list-item.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__end {
  align-self: center;
  margin-top: 0;
}
.mdc-evolution-list-item.mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__end {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-evolution-list-item.mdc-evolution-list-item--disabled, .mdc-evolution-list-item.mdc-evolution-list-item--non-interactive {
  cursor: auto;
}
.mdc-evolution-list-item:not(.mdc-evolution-list-item--selected):focus::before, .mdc-evolution-list-item.mdc-ripple-upgraded--background-focused::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
}
.mdc-evolution-list-item.mdc-evolution-list-item--selected::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px double transparent;
  border-radius: inherit;
  content: "";
}

a.mdc-evolution-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-evolution-list-item__start {
  fill: currentColor;
  flex-shrink: 0;
}

.mdc-evolution-list-item__end {
  flex-shrink: 0;
}

.mdc-evolution-list-item__content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  flex: 1;
}
.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__content, .mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__content {
  align-self: stretch;
}
.mdc-evolution-list-item__content[for] {
  pointer-events: none;
}

.mdc-evolution-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text, .mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::before, .mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::after, .mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-evolution-list-item__secondary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-evolution-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-evolution-list-item__overline-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__overline-text, .mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__overline-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__overline-text::before, .mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__overline-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__overline-text::after, .mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__overline-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-evolution-list-item--with-leading-avatar.mdc-evolution-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-avatar.mdc-evolution-list-item, .mdc-evolution-list-item--with-leading-avatar.mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-evolution-list-item--with-leading-avatar .mdc-evolution-list-item__start {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-avatar .mdc-evolution-list-item__start, .mdc-evolution-list-item--with-leading-avatar .mdc-evolution-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-evolution-list-item--with-leading-avatar .mdc-evolution-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-evolution-list-item--with-leading-avatar.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-evolution-list-item--with-leading-avatar.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-avatar.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-evolution-list-item--with-leading-avatar.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-evolution-list-item--with-leading-avatar.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-avatar.mdc-evolution-list-item--with-one-line {
  height: 56px;
}
.mdc-evolution-list-item--with-leading-avatar.mdc-evolution-list-item--with-two-lines {
  height: 72px;
}
.mdc-evolution-list-item--with-leading-avatar .mdc-evolution-list-item__start {
  border-radius: 50%;
}

.mdc-evolution-list-item--with-leading-icon .mdc-evolution-list-item__start {
  width: 24px;
  height: 24px;
}
.mdc-evolution-list-item--with-leading-icon.mdc-evolution-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-icon.mdc-evolution-list-item, .mdc-evolution-list-item--with-leading-icon.mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-evolution-list-item--with-leading-icon .mdc-evolution-list-item__start {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 32px;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-icon .mdc-evolution-list-item__start, .mdc-evolution-list-item--with-leading-icon .mdc-evolution-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-evolution-list-item--with-leading-icon.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-evolution-list-item--with-leading-icon.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-icon.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-evolution-list-item--with-leading-icon.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-evolution-list-item--with-leading-icon.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-icon.mdc-evolution-list-item--with-one-line {
  height: 56px;
}
.mdc-evolution-list-item--with-leading-icon.mdc-evolution-list-item--with-two-lines {
  height: 72px;
}

.mdc-evolution-list-item--with-leading-thumbnail.mdc-evolution-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-thumbnail.mdc-evolution-list-item, .mdc-evolution-list-item--with-leading-thumbnail.mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-evolution-list-item--with-leading-thumbnail .mdc-evolution-list-item__start {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-thumbnail .mdc-evolution-list-item__start, .mdc-evolution-list-item--with-leading-thumbnail .mdc-evolution-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-evolution-list-item--with-leading-thumbnail .mdc-evolution-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-evolution-list-item--with-leading-thumbnail.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-evolution-list-item--with-leading-thumbnail.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-thumbnail.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-evolution-list-item--with-leading-thumbnail.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-evolution-list-item--with-leading-thumbnail.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-thumbnail.mdc-evolution-list-item--with-one-line {
  height: 56px;
}
.mdc-evolution-list-item--with-leading-thumbnail.mdc-evolution-list-item--with-two-lines {
  height: 72px;
}

.mdc-evolution-list-item--with-leading-image.mdc-evolution-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-image.mdc-evolution-list-item, .mdc-evolution-list-item--with-leading-image.mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-evolution-list-item--with-leading-image .mdc-evolution-list-item__start {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-image .mdc-evolution-list-item__start, .mdc-evolution-list-item--with-leading-image .mdc-evolution-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-evolution-list-item--with-leading-image .mdc-evolution-list-item__start {
  width: 56px;
  height: 56px;
}
.mdc-evolution-list-item--with-leading-image.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-evolution-list-item--with-leading-image.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-image.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-evolution-list-item--with-leading-image.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-evolution-list-item--with-leading-image.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-image.mdc-evolution-list-item--with-one-line {
  height: 72px;
}
.mdc-evolution-list-item--with-leading-image.mdc-evolution-list-item--with-two-lines {
  height: 72px;
}

.mdc-evolution-list-item--with-leading-video.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}
.mdc-evolution-list-item--with-leading-video.mdc-evolution-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-video.mdc-evolution-list-item, .mdc-evolution-list-item--with-leading-video.mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-evolution-list-item--with-leading-video .mdc-evolution-list-item__start {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-video .mdc-evolution-list-item__start, .mdc-evolution-list-item--with-leading-video .mdc-evolution-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
.mdc-evolution-list-item--with-leading-video .mdc-evolution-list-item__start {
  width: 100px;
  height: 56px;
}
.mdc-evolution-list-item--with-leading-video.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-evolution-list-item--with-leading-video.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-video.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-evolution-list-item--with-leading-video.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-evolution-list-item--with-leading-video.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-video.mdc-evolution-list-item--with-one-line {
  height: 72px;
}
.mdc-evolution-list-item--with-leading-video.mdc-evolution-list-item--with-two-lines {
  height: 72px;
}

.mdc-evolution-list-item--with-leading-checkbox.mdc-evolution-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-checkbox.mdc-evolution-list-item, .mdc-evolution-list-item--with-leading-checkbox.mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-evolution-list-item--with-leading-checkbox .mdc-evolution-list-item__start {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 24px;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-checkbox .mdc-evolution-list-item__start, .mdc-evolution-list-item--with-leading-checkbox .mdc-evolution-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 24px;
  /* @noflip */
  margin-right: 8px;
}
.mdc-evolution-list-item--with-leading-checkbox .mdc-evolution-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-evolution-list-item--with-leading-checkbox.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}
.mdc-evolution-list-item--with-leading-checkbox.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-evolution-list-item--with-leading-checkbox.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-checkbox.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-evolution-list-item--with-leading-checkbox.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-evolution-list-item--with-leading-checkbox.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-checkbox.mdc-evolution-list-item--with-one-line {
  height: 56px;
}
.mdc-evolution-list-item--with-leading-checkbox.mdc-evolution-list-item--with-two-lines {
  height: 72px;
}

.mdc-evolution-list-item--with-leading-radio.mdc-evolution-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-radio.mdc-evolution-list-item, .mdc-evolution-list-item--with-leading-radio.mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-evolution-list-item--with-leading-radio .mdc-evolution-list-item__start {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 24px;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-radio .mdc-evolution-list-item__start, .mdc-evolution-list-item--with-leading-radio .mdc-evolution-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 24px;
  /* @noflip */
  margin-right: 8px;
}
.mdc-evolution-list-item--with-leading-radio .mdc-evolution-list-item__start {
  width: 40px;
  height: 40px;
}
.mdc-evolution-list-item--with-leading-radio.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__start {
  align-self: flex-start;
  margin-top: 8px;
}
.mdc-evolution-list-item--with-leading-radio.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-evolution-list-item--with-leading-radio.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-radio.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-evolution-list-item--with-leading-radio.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-evolution-list-item--with-leading-radio.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-radio.mdc-evolution-list-item--with-one-line {
  height: 56px;
}
.mdc-evolution-list-item--with-leading-radio.mdc-evolution-list-item--with-two-lines {
  height: 72px;
}

.mdc-evolution-list-item--with-leading-switch.mdc-evolution-list-item {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-switch.mdc-evolution-list-item, .mdc-evolution-list-item--with-leading-switch.mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
.mdc-evolution-list-item--with-leading-switch .mdc-evolution-list-item__start {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-evolution-list-item--with-leading-switch .mdc-evolution-list-item__start, .mdc-evolution-list-item--with-leading-switch .mdc-evolution-list-item__start[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-evolution-list-item--with-leading-switch .mdc-evolution-list-item__start {
  width: 36px;
  height: 20px;
}
.mdc-evolution-list-item--with-leading-switch.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__start {
  align-self: flex-start;
  margin-top: 16px;
}
.mdc-evolution-list-item--with-leading-switch.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-evolution-list-item--with-leading-switch.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-switch.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-evolution-list-item--with-leading-switch.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-evolution-list-item--with-leading-switch.mdc-evolution-list-item--with-two-lines.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-leading-switch.mdc-evolution-list-item--with-one-line {
  height: 56px;
}
.mdc-evolution-list-item--with-leading-switch.mdc-evolution-list-item--with-two-lines {
  height: 72px;
}

.mdc-evolution-list-item--with-trailing-icon.mdc-evolution-list-item {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-evolution-list-item--with-trailing-icon.mdc-evolution-list-item, .mdc-evolution-list-item--with-trailing-icon.mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
.mdc-evolution-list-item--with-trailing-icon .mdc-evolution-list-item__end {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-evolution-list-item--with-trailing-icon .mdc-evolution-list-item__end, .mdc-evolution-list-item--with-trailing-icon .mdc-evolution-list-item__end[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-evolution-list-item--with-trailing-icon .mdc-evolution-list-item__end {
  width: 24px;
  height: 24px;
}

.mdc-evolution-list-item--with-trailing-meta.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__end {
  align-self: flex-start;
  margin-top: 0;
}
.mdc-evolution-list-item--with-trailing-meta.mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__end {
  align-self: flex-start;
  margin-top: 0;
}
.mdc-evolution-list-item--with-trailing-meta.mdc-evolution-list-item {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-evolution-list-item--with-trailing-meta.mdc-evolution-list-item, .mdc-evolution-list-item--with-trailing-meta.mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end {
  /* @noflip */
  margin-left: 28px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end, .mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 28px;
}
.mdc-evolution-list-item--with-trailing-meta.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-evolution-list-item--with-trailing-meta.mdc-evolution-list-item--with-two-lines .mdc-evolution-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-trailing-meta.mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__end {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
}
.mdc-evolution-list-item--with-trailing-meta.mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__end::before {
  display: inline-block;
  width: 0;
  height: 28px;
  content: "";
  vertical-align: 0;
}
.mdc-evolution-list-item--with-trailing-meta .mdc-evolution-list-item__end {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.mdc-evolution-list-item--with-trailing-checkbox.mdc-evolution-list-item {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-evolution-list-item--with-trailing-checkbox.mdc-evolution-list-item, .mdc-evolution-list-item--with-trailing-checkbox.mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
.mdc-evolution-list-item--with-trailing-checkbox .mdc-evolution-list-item__end {
  /* @noflip */
  margin-left: 24px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-evolution-list-item--with-trailing-checkbox .mdc-evolution-list-item__end, .mdc-evolution-list-item--with-trailing-checkbox .mdc-evolution-list-item__end[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 24px;
}
.mdc-evolution-list-item--with-trailing-checkbox .mdc-evolution-list-item__end {
  width: 40px;
  height: 40px;
}
.mdc-evolution-list-item--with-trailing-checkbox.mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__end {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-evolution-list-item--with-trailing-radio.mdc-evolution-list-item {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-evolution-list-item--with-trailing-radio.mdc-evolution-list-item, .mdc-evolution-list-item--with-trailing-radio.mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
.mdc-evolution-list-item--with-trailing-radio .mdc-evolution-list-item__end {
  /* @noflip */
  margin-left: 24px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-evolution-list-item--with-trailing-radio .mdc-evolution-list-item__end, .mdc-evolution-list-item--with-trailing-radio .mdc-evolution-list-item__end[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 24px;
}
.mdc-evolution-list-item--with-trailing-radio .mdc-evolution-list-item__end {
  width: 40px;
  height: 40px;
}
.mdc-evolution-list-item--with-trailing-radio.mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__end {
  align-self: flex-start;
  margin-top: 8px;
}

.mdc-evolution-list-item--with-trailing-switch.mdc-evolution-list-item {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-evolution-list-item--with-trailing-switch.mdc-evolution-list-item, .mdc-evolution-list-item--with-trailing-switch.mdc-evolution-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: auto;
}
.mdc-evolution-list-item--with-trailing-switch .mdc-evolution-list-item__end {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .mdc-evolution-list-item--with-trailing-switch .mdc-evolution-list-item__end, .mdc-evolution-list-item--with-trailing-switch .mdc-evolution-list-item__end[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 16px;
}
.mdc-evolution-list-item--with-trailing-switch .mdc-evolution-list-item__end {
  width: 36px;
  height: 20px;
}
.mdc-evolution-list-item--with-trailing-switch.mdc-evolution-list-item--with-three-lines .mdc-evolution-list-item__end {
  align-self: flex-start;
  margin-top: 16px;
}

.mdc-evolution-list-group .mdc-list {
  padding: 0;
}

.mdc-evolution-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  margin: calc((3rem - 1.5rem) / 2) 16px;
}

.mdc-evolution-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mdc-evolution-list-divider {
  height: 1px;
  padding: 0;
  background-clip: content-box;
}
.mdc-evolution-list-divider.mdc-evolution-list-divider--with-leading-padding {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-divider.mdc-evolution-list-divider--with-leading-padding, .mdc-evolution-list-divider.mdc-evolution-list-divider--with-leading-padding[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 16px;
}
.mdc-evolution-list-divider.mdc-evolution-list-divider--with-leading-inset {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-divider.mdc-evolution-list-divider--with-leading-inset, .mdc-evolution-list-divider.mdc-evolution-list-divider--with-leading-inset[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 16px;
}
.mdc-evolution-list-divider.mdc-evolution-list-divider--with-trailing-inset {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-evolution-list-divider.mdc-evolution-list-divider--with-trailing-inset, .mdc-evolution-list-divider.mdc-evolution-list-divider--with-trailing-inset[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: auto;
}

.mdc-evolution-list-divider--with-leading-icon.mdc-evolution-list-divider--with-leading-inset {
  /* @noflip */
  padding-left: 72px;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-divider--with-leading-icon.mdc-evolution-list-divider--with-leading-inset, .mdc-evolution-list-divider--with-leading-icon.mdc-evolution-list-divider--with-leading-inset[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 72px;
}

.mdc-evolution-list-divider--with-leading-image.mdc-evolution-list-divider--with-leading-inset {
  /* @noflip */
  padding-left: 88px;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-divider--with-leading-image.mdc-evolution-list-divider--with-leading-inset, .mdc-evolution-list-divider--with-leading-image.mdc-evolution-list-divider--with-leading-inset[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 88px;
}

.mdc-evolution-list-divider--with-leading-thumbnail.mdc-evolution-list-divider--with-leading-inset {
  /* @noflip */
  padding-left: 72px;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-divider--with-leading-thumbnail.mdc-evolution-list-divider--with-leading-inset, .mdc-evolution-list-divider--with-leading-thumbnail.mdc-evolution-list-divider--with-leading-inset[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 72px;
}

.mdc-evolution-list-divider--with-leading-video.mdc-evolution-list-divider--with-leading-padding {
  /* @noflip */
  padding-left: 0px;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-divider--with-leading-video.mdc-evolution-list-divider--with-leading-padding, .mdc-evolution-list-divider--with-leading-video.mdc-evolution-list-divider--with-leading-padding[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 0px;
}
.mdc-evolution-list-divider--with-leading-video.mdc-evolution-list-divider--with-leading-inset {
  /* @noflip */
  padding-left: 116px;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-divider--with-leading-video.mdc-evolution-list-divider--with-leading-inset, .mdc-evolution-list-divider--with-leading-video.mdc-evolution-list-divider--with-leading-inset[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 116px;
}

.mdc-evolution-list-divider--with-leading-avatar.mdc-evolution-list-divider--with-leading-inset {
  /* @noflip */
  padding-left: 72px;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-divider--with-leading-avatar.mdc-evolution-list-divider--with-leading-inset, .mdc-evolution-list-divider--with-leading-avatar.mdc-evolution-list-divider--with-leading-inset[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 72px;
}

.mdc-evolution-list-divider--with-leading-checkbox.mdc-evolution-list-divider--with-leading-inset {
  /* @noflip */
  padding-left: 72px;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-divider--with-leading-checkbox.mdc-evolution-list-divider--with-leading-inset, .mdc-evolution-list-divider--with-leading-checkbox.mdc-evolution-list-divider--with-leading-inset[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 72px;
}

.mdc-evolution-list-divider--with-leading-switch.mdc-evolution-list-divider--with-leading-inset {
  /* @noflip */
  padding-left: 84px;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-divider--with-leading-switch.mdc-evolution-list-divider--with-leading-inset, .mdc-evolution-list-divider--with-leading-switch.mdc-evolution-list-divider--with-leading-inset[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 84px;
}

.mdc-evolution-list-divider--with-leading-radio.mdc-evolution-list-divider--with-leading-inset {
  /* @noflip */
  padding-left: 72px;
  /* @noflip */
  padding-right: auto;
}
[dir=rtl] .mdc-evolution-list-divider--with-leading-radio.mdc-evolution-list-divider--with-leading-inset, .mdc-evolution-list-divider--with-leading-radio.mdc-evolution-list-divider--with-leading-inset[dir=rtl] {
  /* @noflip */
  padding-left: auto;
  /* @noflip */
  padding-right: 72px;
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
:not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-list-item--disabled).mdc-list-item:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated .mdc-list-item__ripple::after {
  background-color: #2f4456;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #2f4456));
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple::after {
  background-color: #2f4456;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #2f4456));
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:hover .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded) .mdc-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-list-item--disabled).mdc-list-item .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-list-item--disabled .mdc-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-list-item--disabled.mdc-ripple-upgraded .mdc-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-list-item--disabled .mdc-list-item__ripple::before,
.mdc-list-item--disabled .mdc-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before, .mdc-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-list-item--disabled .mdc-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item .mdc-evolution-list-item__ripple::before,
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item .mdc-evolution-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item .mdc-evolution-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item .mdc-evolution-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item.mdc-ripple-upgraded .mdc-evolution-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item.mdc-ripple-upgraded .mdc-evolution-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item.mdc-ripple-upgraded--unbounded .mdc-evolution-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item.mdc-ripple-upgraded--foreground-activation .mdc-evolution-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item.mdc-ripple-upgraded--foreground-deactivation .mdc-evolution-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item .mdc-evolution-list-item__ripple::before,
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item .mdc-evolution-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item.mdc-ripple-upgraded .mdc-evolution-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item .mdc-evolution-list-item__ripple::before, :not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item .mdc-evolution-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item:hover .mdc-evolution-list-item__ripple::before, :not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item.mdc-ripple-surface--hover .mdc-evolution-list-item__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item.mdc-ripple-upgraded--background-focused .mdc-evolution-list-item__ripple::before, :not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item:not(.mdc-ripple-upgraded):focus .mdc-evolution-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item:not(.mdc-ripple-upgraded) .mdc-evolution-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item:not(.mdc-ripple-upgraded):active .mdc-evolution-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--activated .mdc-evolution-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-activated-opacity, 0.12);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--activated .mdc-evolution-list-item__ripple::before, :not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--activated .mdc-evolution-list-item__ripple::after {
  background-color: #2f4456;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #2f4456));
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--activated:hover .mdc-evolution-list-item__ripple::before, :not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--activated.mdc-ripple-surface--hover .mdc-evolution-list-item__ripple::before {
  opacity: 0.16;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.16);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--activated.mdc-ripple-upgraded--background-focused .mdc-evolution-list-item__ripple::before, :not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--activated:not(.mdc-ripple-upgraded):focus .mdc-evolution-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--activated:not(.mdc-ripple-upgraded) .mdc-evolution-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--activated:not(.mdc-ripple-upgraded):active .mdc-evolution-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--selected .mdc-evolution-list-item__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-selected-opacity, 0.08);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--selected .mdc-evolution-list-item__ripple::before, :not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--selected .mdc-evolution-list-item__ripple::after {
  background-color: #2f4456;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #2f4456));
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--selected:hover .mdc-evolution-list-item__ripple::before, :not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--selected.mdc-ripple-surface--hover .mdc-evolution-list-item__ripple::before {
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.12);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--selected.mdc-ripple-upgraded--background-focused .mdc-evolution-list-item__ripple::before, :not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--selected:not(.mdc-ripple-upgraded):focus .mdc-evolution-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.2);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--selected:not(.mdc-ripple-upgraded) .mdc-evolution-list-item__ripple::after {
  transition: opacity 150ms linear;
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--selected:not(.mdc-ripple-upgraded):active .mdc-evolution-list-item__ripple::after {
  transition-duration: 75ms;
  opacity: 0.2;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.2);
}
:not(.mdc-evolution-list-item--disabled).mdc-evolution-list-item .mdc-evolution-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-evolution-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-evolution-list-item--disabled .mdc-evolution-list-item__ripple::before,
.mdc-evolution-list-item--disabled .mdc-evolution-list-item__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-evolution-list-item--disabled .mdc-evolution-list-item__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-evolution-list-item--disabled .mdc-evolution-list-item__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-evolution-list-item--disabled.mdc-ripple-upgraded .mdc-evolution-list-item__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-evolution-list-item--disabled.mdc-ripple-upgraded .mdc-evolution-list-item__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-evolution-list-item--disabled.mdc-ripple-upgraded--unbounded .mdc-evolution-list-item__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-evolution-list-item--disabled.mdc-ripple-upgraded--foreground-activation .mdc-evolution-list-item__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-evolution-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation .mdc-evolution-list-item__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-evolution-list-item--disabled .mdc-evolution-list-item__ripple::before,
.mdc-evolution-list-item--disabled .mdc-evolution-list-item__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-evolution-list-item--disabled.mdc-ripple-upgraded .mdc-evolution-list-item__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-evolution-list-item--disabled .mdc-evolution-list-item__ripple::before,
.mdc-evolution-list-item--disabled .mdc-evolution-list-item__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-evolution-list-item--disabled.mdc-ripple-upgraded--background-focused .mdc-evolution-list-item__ripple::before, .mdc-evolution-list-item--disabled:not(.mdc-ripple-upgraded):focus .mdc-evolution-list-item__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-evolution-list-item--disabled .mdc-evolution-list-item__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-touch-target-wrapper {
  display: inline;
}

.mdc-elevation-overlay {
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  /* @alternate */
  opacity: var(--mdc-elevation-overlay-opacity, 0);
  transition: opacity 280ms cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-elevation-overlay-color, #fff);
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  /* @alternate */
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: visible;
  vertical-align: middle;
}
.mdc-button .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
.mdc-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-button:active {
  outline: none;
}
.mdc-button:hover {
  cursor: pointer;
}
.mdc-button:disabled {
  cursor: default;
  pointer-events: none;
}
.mdc-button .mdc-button__icon {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  vertical-align: top;
}
[dir=rtl] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
.mdc-button .mdc-button__touch {
  position: absolute;
  top: 50%;
  right: 0;
  height: 48px;
  left: 0;
  transform: translateY(-50%);
}

.mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-button__label + .mdc-button__icon, .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

svg.mdc-button__icon {
  fill: currentColor;
}

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}
.mdc-button--raised .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}
[dir=rtl] .mdc-button--raised .mdc-button__label + .mdc-button__icon, .mdc-button--raised .mdc-button__label + .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}

.mdc-button--touch {
  margin-top: 6px;
  margin-bottom: 6px;
}

.mdc-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-button .mdc-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-button .mdc-button__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-button.mdc-ripple-upgraded--unbounded .mdc-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-button.mdc-ripple-upgraded--foreground-activation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-button.mdc-ripple-upgraded--foreground-deactivation .mdc-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button .mdc-button__ripple::before,
.mdc-button .mdc-button__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-button.mdc-ripple-upgraded .mdc-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-button .mdc-button__ripple {
  position: absolute;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mdc-button:not(.mdc-button--outlined) .mdc-button__ripple {
  top: 0;
  left: 0;
}

.mdc-button--raised {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-button--raised:hover, .mdc-button--raised:focus {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:active {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:disabled {
  /* @alternate */
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-button--outlined {
  border-style: solid;
}

.mdc-button {
  height: 36px;
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
  padding: 0 8px 0 8px;
}
.mdc-button:not(:disabled) {
  background-color: transparent;
}
.mdc-button:disabled {
  background-color: transparent;
}
.mdc-button:not(:disabled) {
  color: #2f4456;
  /* @alternate */
  color: var(--mdc-theme-primary, #2f4456);
}
.mdc-button:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-button .mdc-button__ripple::before, .mdc-button .mdc-button__ripple::after {
  background-color: #2f4456;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #2f4456));
}
.mdc-button:hover .mdc-button__ripple::before, .mdc-button.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-button.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-button:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-button--unelevated {
  padding: 0 16px 0 16px;
  height: 36px;
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}
.mdc-button--unelevated:not(:disabled) {
  background-color: #2f4456;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #2f4456);
}
.mdc-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mdc-button--unelevated:not(:disabled) {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.mdc-button--unelevated:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-button--unelevated .mdc-button__ripple::before, .mdc-button--unelevated .mdc-button__ripple::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #fff));
}
.mdc-button--unelevated:hover .mdc-button__ripple::before, .mdc-button--unelevated.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-button--unelevated.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--unelevated:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-button--unelevated:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button--unelevated:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-button--unelevated .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-button--raised {
  padding: 0 16px 0 16px;
  height: 36px;
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}
.mdc-button--raised:not(:disabled) {
  background-color: #2f4456;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #2f4456);
}
.mdc-button--raised:disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:not(:disabled) {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.mdc-button--raised:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-button--raised .mdc-button__ripple::before, .mdc-button--raised .mdc-button__ripple::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #fff));
}
.mdc-button--raised:hover .mdc-button__ripple::before, .mdc-button--raised.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-button--raised.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--raised:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-button--raised:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button--raised:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-button--raised.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-button--raised .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-button--outlined {
  height: 36px;
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
  padding: 0 15px 0 15px;
  border-width: 1px;
}
.mdc-button--outlined:not(:disabled) {
  background-color: transparent;
}
.mdc-button--outlined:disabled {
  background-color: transparent;
}
.mdc-button--outlined:not(:disabled) {
  color: #2f4456;
  /* @alternate */
  color: var(--mdc-theme-primary, #2f4456);
}
.mdc-button--outlined:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-button--outlined .mdc-button__ripple::before, .mdc-button--outlined .mdc-button__ripple::after {
  background-color: #2f4456;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #2f4456));
}
.mdc-button--outlined:hover .mdc-button__ripple::before, .mdc-button--outlined.mdc-ripple-surface--hover .mdc-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-button--outlined.mdc-ripple-upgraded--background-focused .mdc-button__ripple::before, .mdc-button--outlined:not(.mdc-ripple-upgraded):focus .mdc-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-button--outlined:not(.mdc-ripple-upgraded) .mdc-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-button--outlined:not(.mdc-ripple-upgraded):active .mdc-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button--outlined.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-button--outlined .mdc-button__ripple {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}
.mdc-button--outlined:not(:disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-button--outlined:disabled {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-button--outlined .mdc-button__ripple {
  top: -1px;
  left: -1px;
  border: 1px solid transparent;
}
.mdc-button--outlined .mdc-button__touch {
  left: -1px;
  width: calc(100% + 2 * 1px);
}

.mdc-icon-button {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  font-size: 24px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mdc-icon-button svg,
.mdc-icon-button img {
  width: 24px;
  height: 24px;
}
.mdc-icon-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}
.mdc-icon-button:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-icon-button__icon {
  display: inline-block;
}
.mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none;
}
.mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: inline-block;
}

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-icon-button::before, .mdc-icon-button::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-icon-button::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-icon-button::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-icon-button.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-icon-button.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button::before, .mdc-icon-button::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-icon-button.mdc-ripple-upgraded::before, .mdc-icon-button.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button::before, .mdc-icon-button::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-icon-button:hover::before, .mdc-icon-button.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-icon-button.mdc-ripple-upgraded--background-focused::before, .mdc-icon-button:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-icon-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-icon-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-menu-surface {
  display: none;
  position: absolute;
  box-sizing: border-box;
  max-width: calc(100vw - 32px);
  /* @alternate */
  max-width: var(--mdc-menu-max-width, calc(100vw - 32px));
  max-height: calc(100vh - 32px);
  /* @alternate */
  max-height: var(--mdc-menu-max-height, calc(100vh - 32px));
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  opacity: 0;
  overflow: auto;
  will-change: transform, opacity;
  z-index: 8;
  transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1), height 250ms cubic-bezier(0, 0, 0.2, 1);
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
  /* @noflip */
  transform-origin-left: top left;
  /* @noflip */
  transform-origin-right: top right;
}
.mdc-menu-surface:focus {
  outline: none;
}
.mdc-menu-surface--open {
  display: inline-block;
  transform: scale(1);
  opacity: 1;
}
.mdc-menu-surface--animating-open {
  display: inline-block;
  transform: scale(0.8);
  opacity: 0;
}
.mdc-menu-surface--animating-closed {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.075s linear;
}
[dir=rtl] .mdc-menu-surface, .mdc-menu-surface[dir=rtl] {
  /* @noflip */
  transform-origin-left: top right;
  /* @noflip */
  transform-origin-right: top left;
}

.mdc-menu-surface--anchor {
  position: relative;
  overflow: visible;
}

.mdc-menu-surface--fixed {
  position: fixed;
}

.mdc-menu-surface--fullwidth {
  width: 100%;
}

.mdc-menu {
  min-width: 112px;
  /* @alternate */
  min-width: var(--mdc-menu-min-width, 112px);
}
.mdc-menu .mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-list {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  position: relative;
}
.mdc-menu .mdc-list .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
.mdc-menu .mdc-list-divider {
  margin: 8px 0;
}
.mdc-menu .mdc-list-item {
  user-select: none;
}
.mdc-menu .mdc-list-item--disabled {
  cursor: auto;
}
.mdc-menu a.mdc-list-item .mdc-list-item__text,
.mdc-menu a.mdc-list-item .mdc-list-item__graphic {
  pointer-events: none;
}

.mdc-menu__selection-group {
  padding: 0;
  fill: currentColor;
}
.mdc-menu__selection-group .mdc-list-item {
  /* @noflip */
  padding-left: 56px;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-menu__selection-group .mdc-list-item, .mdc-menu__selection-group .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 56px;
}
.mdc-menu__selection-group .mdc-menu__selection-group-icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
[dir=rtl] .mdc-menu__selection-group .mdc-menu__selection-group-icon, .mdc-menu__selection-group .mdc-menu__selection-group-icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-menu-item--selected .mdc-menu__selection-group-icon {
  display: inline;
}

.nty-container-settings-backup {
  margin: 0 auto;
  max-width: 600px;
}

.nty-button-drive {
  text-transform: none;
}

.mdc-menu.nty-menu-backup {
  width: 186px;
}
.mdc-menu.nty-menu-backup .mdc-list-item {
  height: 40px;
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  position: absolute;
  /* @noflip */
  left: 0;
  /* @noflip */
  transform-origin: left top;
  line-height: 1.15rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  /* @alternate */
  will-change: transform;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-floating-label, .mdc-floating-label[dir=rtl] {
  /* @noflip */
  right: 0;
  /* @noflip */
  left: auto;
  /* @noflip */
  transform-origin: right top;
  /* @noflip */
  text-align: right;
}

.mdc-floating-label--float-above {
  cursor: auto;
}

.mdc-floating-label--required::after {
  /* @noflip */
  margin-left: 1px;
  /* @noflip */
  margin-right: 0px;
  content: "*";
}
[dir=rtl] .mdc-floating-label--required::after, .mdc-floating-label--required[dir=rtl]::after {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 1px;
}

.mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-106%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-106%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-106%) scale(0.75);
  }
}
.mdc-line-ripple::before, .mdc-line-ripple::after {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom-style: solid;
  content: "";
}
.mdc-line-ripple::before {
  border-bottom-width: 1px;
  z-index: 1;
}
.mdc-line-ripple::after {
  transform: scaleX(0);
  border-bottom-width: 2px;
  opacity: 0;
  z-index: 2;
}
.mdc-line-ripple::after {
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-line-ripple--active::after {
  transform: scaleX(1);
  opacity: 1;
}

.mdc-line-ripple--deactivating::after {
  opacity: 0;
}

.mdc-notched-outline {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* @noflip */
  text-align: left;
  pointer-events: none;
}
[dir=rtl] .mdc-notched-outline, .mdc-notched-outline[dir=rtl] {
  /* @noflip */
  text-align: right;
}
.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  box-sizing: border-box;
  height: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  pointer-events: none;
}
.mdc-notched-outline__leading {
  /* @noflip */
  border-left: 1px solid;
  /* @noflip */
  border-right: none;
  width: 12px;
}
[dir=rtl] .mdc-notched-outline__leading, .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-left: none;
  /* @noflip */
  border-right: 1px solid;
}
.mdc-notched-outline__trailing {
  /* @noflip */
  border-left: none;
  /* @noflip */
  border-right: 1px solid;
  flex-grow: 1;
}
[dir=rtl] .mdc-notched-outline__trailing, .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-left: 1px solid;
  /* @noflip */
  border-right: none;
}
.mdc-notched-outline__notch {
  flex: 0 0 auto;
  width: auto;
  max-width: calc(100% - 12px * 2);
}
.mdc-notched-outline .mdc-floating-label {
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.mdc-notched-outline .mdc-floating-label--float-above {
  text-overflow: clip;
}
.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: 133.3333333333%;
}

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
  border-top: none;
}
[dir=rtl] .mdc-notched-outline--notched .mdc-notched-outline__notch, .mdc-notched-outline--notched .mdc-notched-outline__notch[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}

.mdc-notched-outline--no-label .mdc-notched-outline__notch {
  display: none;
}

.mdc-text-field--filled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-text-field--filled .mdc-text-field__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-text-field--filled .mdc-text-field__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-text-field--filled.mdc-ripple-upgraded--unbounded .mdc-text-field__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-text-field--filled.mdc-ripple-upgraded--foreground-activation .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-text-field--filled.mdc-ripple-upgraded--foreground-deactivation .mdc-text-field__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-text-field--filled.mdc-ripple-upgraded .mdc-text-field__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-text-field__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mdc-text-field {
  border-top-left-radius: 4px;
  /* @alternate */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  border-top-right-radius: 4px;
  /* @alternate */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  display: inline-flex;
  align-items: baseline;
  padding: 0 16px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  /* @alternate */
  will-change: opacity, transform, color;
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.54);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: #2f4456;
  /* @alternate */
  caret-color: var(--mdc-theme-primary, #2f4456);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field .mdc-floating-label {
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  height: 28px;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  min-width: 0;
  border: none;
  border-radius: 0;
  background: none;
  appearance: none;
  padding: 0;
}
.mdc-text-field__input::-ms-clear {
  display: none;
}
.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: none;
}
.mdc-text-field__input:focus {
  outline: none;
}
.mdc-text-field__input:invalid {
  box-shadow: none;
}
@media all {
  .mdc-text-field__input::placeholder {
    transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }
}
@media all {
  .mdc-text-field__input:-ms-input-placeholder {
    transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
  }
}
@media all {
  .mdc-text-field--no-label .mdc-text-field__input::placeholder, .mdc-text-field--focused .mdc-text-field__input::placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1;
  }
}
@media all {
  .mdc-text-field--no-label .mdc-text-field__input:-ms-input-placeholder, .mdc-text-field--focused .mdc-text-field__input:-ms-input-placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1;
  }
}

.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
  height: 28px;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  white-space: nowrap;
}
.mdc-text-field--label-floating .mdc-text-field__affix, .mdc-text-field--no-label .mdc-text-field__affix {
  opacity: 1;
}
@supports (-webkit-hyphens: none) {
  .mdc-text-field--outlined .mdc-text-field__affix {
    align-items: center;
    align-self: center;
    display: inline-flex;
    height: 100%;
  }
}

.mdc-text-field__affix--prefix {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 2px;
}
[dir=rtl] .mdc-text-field__affix--prefix, .mdc-text-field__affix--prefix[dir=rtl] {
  /* @noflip */
  padding-left: 2px;
  /* @noflip */
  padding-right: 0;
}
.mdc-text-field--end-aligned .mdc-text-field__affix--prefix {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__affix--prefix, .mdc-text-field--end-aligned .mdc-text-field__affix--prefix[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}

.mdc-text-field__affix--suffix {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-text-field__affix--suffix, .mdc-text-field__affix--suffix[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}
.mdc-text-field--end-aligned .mdc-text-field__affix--suffix {
  /* @noflip */
  padding-left: 2px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__affix--suffix, .mdc-text-field--end-aligned .mdc-text-field__affix--suffix[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 2px;
}

.mdc-text-field--filled {
  height: 56px;
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: rgb(244.8, 244.8, 244.8);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: #2f4456;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-primary, #2f4456);
}
.mdc-text-field--filled .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--filled .mdc-floating-label, .mdc-text-field--filled .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-106%) scale(0.75);
}
.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
  height: 100%;
}
.mdc-text-field--filled.mdc-text-field--no-label .mdc-floating-label {
  display: none;
}
.mdc-text-field--filled.mdc-text-field--no-label::before {
  display: none;
}
@supports (-webkit-hyphens: none) {
  .mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__affix {
    align-items: center;
    align-self: center;
    display: inline-flex;
    height: 100%;
  }
}

.mdc-text-field--outlined {
  height: 56px;
  overflow: visible;
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) scale(1);
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75);
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-34.75px) scale(0.75);
  }
}
.mdc-text-field--outlined .mdc-text-field__input {
  height: 100%;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #2f4456;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #2f4456);
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-bottom-left-radius: 0;
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: max(12px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__notch {
    max-width: calc(100% - max(12px, var(--mdc-shape-small, 4px)) * 2);
  }
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-top-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-bottom-right-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-bottom-right-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-bottom-left-radius: 0;
}
[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-top-left-radius: var(--mdc-shape-small, 4px);
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 4px;
  /* @alternate */
  /* @noflip */
  border-bottom-left-radius: var(--mdc-shape-small, 4px);
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined {
    /* @noflip */
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined {
    /* @noflip */
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined + .mdc-text-field-helper-line {
    /* @noflip */
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined + .mdc-text-field-helper-line {
    /* @noflip */
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
.mdc-text-field--outlined.mdc-text-field--with-leading-icon {
  /* @noflip */
  padding-left: 0;
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined.mdc-text-field--with-leading-icon {
    /* @noflip */
    padding-right: max(16px, var(--mdc-shape-small, 4px));
  }
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /* @noflip */
  padding-right: 0;
}
@supports (top: max(0%)) {
  [dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
    /* @noflip */
    padding-left: max(16px, var(--mdc-shape-small, 4px));
  }
}
.mdc-text-field--outlined.mdc-text-field--with-trailing-icon {
  /* @noflip */
  padding-right: 0;
}
@supports (top: max(0%)) {
  .mdc-text-field--outlined.mdc-text-field--with-trailing-icon {
    /* @noflip */
    padding-left: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /* @noflip */
  padding-left: 0;
}
@supports (top: max(0%)) {
  [dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
    /* @noflip */
    padding-right: max(16px, calc(var(--mdc-shape-small, 4px) + 4px));
  }
}
.mdc-text-field--outlined.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 0;
}
.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 1px;
}
.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  content: none;
}
.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 4px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 4px;
}
.mdc-text-field--outlined .mdc-text-field__input {
  display: flex;
  border: none !important;
  background-color: transparent;
}
.mdc-text-field--outlined .mdc-notched-outline {
  z-index: 1;
}

.mdc-text-field--textarea {
  flex-direction: column;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0;
  transition: none;
}
.mdc-text-field--textarea .mdc-floating-label {
  top: 19px;
}
.mdc-text-field--textarea .mdc-floating-label:not(.mdc-floating-label--float-above) {
  transform: none;
}
.mdc-text-field--textarea .mdc-text-field__input {
  flex-grow: 1;
  height: auto;
  min-height: 1.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  resize: none;
  padding: 0 16px;
  line-height: 1.5rem;
}
.mdc-text-field--textarea.mdc-text-field--filled::before {
  display: none;
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-10.25px) scale(0.75);
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea-filled 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-textarea-filled {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-10.25px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-10.25px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-10.25px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-10.25px) scale(0.75);
  }
}
.mdc-text-field--textarea.mdc-text-field--filled .mdc-text-field__input {
  margin-top: 23px;
  margin-bottom: 9px;
}
.mdc-text-field--textarea.mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 0;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-27.25px) scale(1);
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-24.75px) scale(0.75);
}
.mdc-text-field--textarea.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-textarea-outlined 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-textarea-outlined {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-24.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-24.75px) scale(0.75);
  }
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-text-field__input {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mdc-text-field--textarea.mdc-text-field--outlined .mdc-floating-label {
  top: 18px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field__input {
  margin-bottom: 2px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter {
  align-self: flex-end;
  padding: 0 16px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter::after {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: -16px;
}
.mdc-text-field--textarea.mdc-text-field--with-internal-counter .mdc-text-field-character-counter::before {
  display: none;
}

.mdc-text-field__resizer {
  align-self: stretch;
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100%;
  max-width: 100%;
  min-height: 56px;
  min-width: fit-content;
  /* @alternate */
  min-width: -moz-available;
  /* @alternate */
  min-width: -webkit-fill-available;
  overflow: hidden;
  resize: both;
}
.mdc-text-field--filled .mdc-text-field__resizer {
  transform: translateY(-1px);
}
.mdc-text-field--filled .mdc-text-field__resizer .mdc-text-field__input,
.mdc-text-field--filled .mdc-text-field__resizer .mdc-text-field-character-counter {
  transform: translateY(1px);
}
.mdc-text-field--outlined .mdc-text-field__resizer {
  transform: translateX(-1px) translateY(-1px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer, .mdc-text-field--outlined .mdc-text-field__resizer[dir=rtl] {
  transform: translateX(1px) translateY(-1px);
}
.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input,
.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter {
  transform: translateX(1px) translateY(1px);
}
[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input, .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field__input[dir=rtl],
[dir=rtl] .mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter,
.mdc-text-field--outlined .mdc-text-field__resizer .mdc-text-field-character-counter[dir=rtl] {
  transform: translateX(-1px) translateY(1px);
}

.mdc-text-field--with-leading-icon {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-text-field--with-leading-icon, .mdc-text-field--with-leading-icon[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
}
.mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 48px);
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 48px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-37.25px) translateX(-32px) scale(1);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-37.25px) translateX(32px) scale(1);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) translateX(-32px) scale(0.75);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-34.75px) translateX(32px) scale(0.75);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-34.75px) scale(0.75);
  }
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -32px)) translateY(-34.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-34.75px) scale(0.75);
  }
}

.mdc-text-field--with-trailing-icon {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--with-trailing-icon, .mdc-text-field--with-trailing-icon[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 64px);
}
.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 64px / 0.75);
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined :not(.mdc-notched-outline--notched) .mdc-notched-outline__notch {
  max-width: calc(100% - 60px);
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 0;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label {
  max-width: calc(100% - 96px);
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--filled .mdc-floating-label--float-above {
  max-width: calc(100% / 0.75 - 96px / 0.75);
}

.mdc-text-field-helper-line {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.mdc-text-field + .mdc-text-field-helper-line {
  padding-right: 16px;
  padding-left: 16px;
}

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(47, 68, 86, 0.87);
}
.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px;
}
.mdc-text-field--focused + .mdc-text-field-helper-line .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1;
}
.mdc-text-field--focused.mdc-text-field--outlined .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 2px;
}
.mdc-text-field--focused.mdc-text-field--outlined.mdc-text-field--textarea .mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-top: 0;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b00020;
  /* @alternate */
  caret-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  opacity: 1;
}

.mdc-text-field--disabled {
  pointer-events: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  cursor: default;
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: rgb(249.9, 249.9, 249.9);
}
.mdc-text-field--disabled.mdc-text-field--filled .mdc-text-field__ripple {
  display: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
  pointer-events: auto;
}

.mdc-text-field--end-aligned .mdc-text-field__input {
  /* @noflip */
  text-align: right;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  text-align: left;
}

[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__input,
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__input,
.mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix {
  /* @noflip */
  direction: ltr;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--prefix {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 2px;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--suffix {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__icon--leading, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__icon--leading {
  order: 1;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--suffix {
  order: 2;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__input, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__input {
  order: 3;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__affix--prefix {
  order: 4;
}
[dir=rtl] .mdc-text-field--ltr-text .mdc-text-field__icon--trailing, .mdc-text-field--ltr-text[dir=rtl] .mdc-text-field__icon--trailing {
  order: 5;
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__input {
  /* @noflip */
  text-align: right;
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__affix--prefix, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__affix--prefix {
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-text-field--ltr-text.mdc-text-field--end-aligned .mdc-text-field__affix--suffix, .mdc-text-field--ltr-text.mdc-text-field--end-aligned[dir=rtl] .mdc-text-field__affix--suffix {
  /* @noflip */
  padding-left: 2px;
}

.mdc-text-field-helper-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  opacity: 0;
  will-change: opacity;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-text-field-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-text-field-character-counter {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
  white-space: nowrap;
}
.mdc-text-field-character-counter::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}
[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}
[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}

.mdc-text-field__icon {
  align-self: center;
  cursor: pointer;
}
.mdc-text-field__icon:not([tabindex]), .mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}
.mdc-text-field__icon svg {
  display: block;
}

.mdc-text-field__icon--leading {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-text-field__icon--leading, .mdc-text-field__icon--leading[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 16px;
}

.mdc-text-field__icon--trailing {
  padding: 12px;
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0px;
}
[dir=rtl] .mdc-text-field__icon--trailing, .mdc-text-field__icon--trailing[dir=rtl] {
  /* @noflip */
  margin-left: 0px;
  /* @noflip */
  margin-right: 0px;
}

.nty-container-settings-deregister {
  margin: 0 auto;
  max-width: 400px;
}

.nty-tf-settings-deregister {
  height: 48px;
  width: stretch;
}
.nty-tf-settings-deregister .mdc-floating-label--float-above {
  transform: translateY(-33.25px) scale(1);
}
.nty-tf-settings-deregister .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.nty-tf-settings-deregister.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-settings-deregister .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-30.75px) scale(0.75);
}
.nty-tf-settings-deregister.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-settings-deregister .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.nty-tf-settings-deregister .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-48px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-48px {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
}

.nty-container-settings-email {
  margin: 0 auto;
  max-width: 400px;
}

.nty-tf-settings-email {
  height: 48px;
  width: stretch;
}
.nty-tf-settings-email .mdc-floating-label--float-above {
  transform: translateY(-33.25px) scale(1);
}
.nty-tf-settings-email .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.nty-tf-settings-email.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-settings-email .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-30.75px) scale(0.75);
}
.nty-tf-settings-email.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-settings-email .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.nty-tf-settings-email .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-48px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-48px {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
}

.nty-container-notebooks {
  margin: 0 auto 24px;
  max-width: 600px;
}

.mdc-menu.nty-menu-notebook {
  width: 148px;
}
.mdc-menu.nty-menu-notebook .mdc-list-item {
  height: 40px;
}

.nty-container-settings-password {
  margin: 0 auto;
  max-width: 400px;
}

.nty-tf-settings-password {
  height: 48px;
  width: stretch;
}
.nty-tf-settings-password .mdc-floating-label--float-above {
  transform: translateY(-33.25px) scale(1);
}
.nty-tf-settings-password .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.nty-tf-settings-password.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-settings-password .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-30.75px) scale(0.75);
}
.nty-tf-settings-password.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-settings-password .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.nty-tf-settings-password .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-48px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-48px {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
}

.nty-container-settings-security {
  margin: 0 auto;
  max-width: 600px;
}

.nty-container-settings-account {
  margin: 0 auto;
  max-width: 600px;
}

.mdc-menu.nty-menu-settings-email,
.mdc-menu.nty-menu-settings-password,
.mdc-menu.nty-menu-settings-google {
  width: 148px;
}
.mdc-menu.nty-menu-settings-email .mdc-list-item,
.mdc-menu.nty-menu-settings-password .mdc-list-item,
.mdc-menu.nty-menu-settings-google .mdc-list-item {
  height: 40px;
}
.mdc-menu.nty-menu-settings-email .mdc-list,
.mdc-menu.nty-menu-settings-password .mdc-list,
.mdc-menu.nty-menu-settings-google .mdc-list {
  padding: 8px 0;
}

.mdc-list.nty-list-settings-account {
  padding: 0;
}
.mdc-list.nty-list-settings-account .mdc-list-item.nty-list-item-select-sort {
  height: 120px;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-bar {
  width: 100%;
}

.mdc-tab {
  height: 48px;
}

.mdc-tab--stacked {
  height: 72px;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-scroller {
  overflow-y: hidden;
}
.mdc-tab-scroller.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-tab-scroller__test {
  position: absolute;
  top: -9999px;
  width: 100px;
  height: 100px;
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: hidden;
}

.mdc-tab-scroller__scroll-area::-webkit-scrollbar,
.mdc-tab-scroller__test::-webkit-scrollbar {
  display: none;
}

.mdc-tab-scroller__scroll-area--scroll {
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-content {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  transform: none;
  will-change: transform;
}

.mdc-tab-scroller--align-start .mdc-tab-scroller__scroll-content {
  justify-content: flex-start;
}

.mdc-tab-scroller--align-end .mdc-tab-scroller__scroll-content {
  justify-content: flex-end;
}

.mdc-tab-scroller--align-center .mdc-tab-scroller__scroll-content {
  justify-content: center;
}

.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: auto;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-indicator {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}
.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: #2f4456;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #2f4456);
}
.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  color: #26a69a;
  /* @alternate */
  color: var(--mdc-theme-secondary, #26a69a);
}
.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-top-width: 2px;
}
.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  height: 34px;
  font-size: 34px;
}

.mdc-tab-indicator__content {
  transform-origin: left;
  opacity: 0;
}

.mdc-tab-indicator__content--underline {
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  border-top-style: solid;
}

.mdc-tab-indicator__content--icon {
  align-self: center;
  margin: 0 auto;
}

.mdc-tab-indicator--active .mdc-tab-indicator__content {
  opacity: 1;
}

.mdc-tab-indicator .mdc-tab-indicator__content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-tab-indicator--no-transition .mdc-tab-indicator__content {
  transition: none;
}

.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition: 150ms opacity linear;
}

.mdc-tab-indicator--active.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition-delay: 100ms;
}

.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  padding-right: 24px;
  padding-left: 24px;
  min-width: 90px;
  position: relative;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  outline: none;
  background: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1;
}
.mdc-tab .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-tab .mdc-tab__icon {
  color: rgba(0, 0, 0, 0.54);
  fill: currentColor;
}
.mdc-tab::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-tab--min-width {
  flex: 0 1 auto;
}

.mdc-tab__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  pointer-events: none;
}

.mdc-tab__text-label {
  transition: 150ms color linear;
  display: inline-block;
  line-height: 1;
  z-index: 2;
}

.mdc-tab__icon {
  transition: 150ms color linear;
  width: 24px;
  height: 24px;
  font-size: 24px;
  z-index: 2;
}

.mdc-tab--stacked .mdc-tab__content {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mdc-tab--stacked .mdc-tab__text-label {
  padding-top: 6px;
  padding-bottom: 4px;
}

.mdc-tab--active .mdc-tab__text-label {
  color: #2f4456;
  /* @alternate */
  color: var(--mdc-theme-primary, #2f4456);
}
.mdc-tab--active .mdc-tab__icon {
  color: #2f4456;
  /* @alternate */
  color: var(--mdc-theme-primary, #2f4456);
  fill: currentColor;
}
.mdc-tab--active .mdc-tab__text-label,
.mdc-tab--active .mdc-tab__icon {
  transition-delay: 100ms;
}

.mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label, .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
}

.mdc-tab {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-tab .mdc-tab__ripple::before,
.mdc-tab .mdc-tab__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-tab .mdc-tab__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-tab .mdc-tab__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-tab.mdc-ripple-upgraded--unbounded .mdc-tab__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-tab.mdc-ripple-upgraded--foreground-activation .mdc-tab__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-tab.mdc-ripple-upgraded--foreground-deactivation .mdc-tab__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-tab .mdc-tab__ripple::before,
.mdc-tab .mdc-tab__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-tab .mdc-tab__ripple::before, .mdc-tab .mdc-tab__ripple::after {
  background-color: #2f4456;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #2f4456));
}
.mdc-tab:hover .mdc-tab__ripple::before, .mdc-tab.mdc-ripple-surface--hover .mdc-tab__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__ripple::before, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-tab:not(.mdc-ripple-upgraded) .mdc-tab__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-tab:not(.mdc-ripple-upgraded):active .mdc-tab__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-tab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-tab__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  will-change: transform, opacity;
}

.nty-container-office {
  margin: 0 auto;
  max-width: 400px;
}

.nty-office-info {
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  padding: 16px;
  white-space: pre;
}

.mdc-dialog,
.mdc-dialog__scrim {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.mdc-dialog {
  display: none;
  z-index: 7;
  /* @alternate */
  z-index: var(--mdc-dialog-z-index, 7);
}
.mdc-dialog .mdc-dialog__surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}
.mdc-dialog .mdc-dialog__scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
.mdc-dialog .mdc-dialog__title {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-dialog .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title, .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions, .mdc-dialog.mdc-dialog--scrollable.mdc-dialog-scroll-divider-footer .mdc-dialog__actions {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-dialog.mdc-dialog-scroll-divider-header.mdc-dialog--fullscreen .mdc-dialog__header {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mdc-dialog .mdc-dialog__content {
  padding: 20px 24px 20px 24px;
}
.mdc-dialog .mdc-dialog__surface {
  min-width: 280px;
}
@media (max-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: calc(100vw - 32px);
  }
}
@media (min-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: 560px;
  }
}
.mdc-dialog .mdc-dialog__surface {
  max-height: calc(100% - 32px);
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mdc-dialog .mdc-dialog__container {
    /* stylelint-disable */
    /* stylelint-enable*/
  }
}
.mdc-dialog .mdc-dialog__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-medium, 4px);
}
@media (max-width: 960px) and (max-height: 1440px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
    max-width: 560px;
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (max-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: calc(100vw - 112px);
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (min-width: 672px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: 560px;
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (max-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: calc(100vh - 160px);
  }
}
@media (max-width: 720px) and (max-height: 1023px) and (min-height: 720px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-height: 560px;
  }
}
@media (max-width: 720px) and (max-height: 400px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
  }
}
@media (max-width: 600px) and (max-height: 960px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    border-radius: 0;
  }
}
@media (min-width: 960px) and (min-height: 1440px) {
  .mdc-dialog.mdc-dialog--fullscreen .mdc-dialog__surface {
    max-width: calc(100vw - 400px);
  }
}

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1;
}

.mdc-dialog__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  height: 100%;
  transform: scale(0.8);
  opacity: 0;
  pointer-events: none;
}

.mdc-dialog__surface {
  /* @alternate */
  position: relative;
  /* @alternate */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  pointer-events: auto;
  overflow-y: auto;
}
.mdc-dialog__surface .mdc-elevation-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  /* @noflip */
  left: 0;
}
.mdc-dialog[dir=rtl] .mdc-dialog__surface, [dir=rtl] .mdc-dialog .mdc-dialog__surface {
  /* @noflip */
  text-align: right;
}

.mdc-dialog__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0 24px 9px;
  border-bottom: 1px solid transparent;
}
.mdc-dialog__title::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.mdc-dialog[dir=rtl] .mdc-dialog__title, [dir=rtl] .mdc-dialog .mdc-dialog__title {
  /* @noflip */
  text-align: right;
}

.mdc-dialog--scrollable .mdc-dialog__title {
  padding-bottom: 15px;
}

.mdc-dialog--fullscreen .mdc-dialog__header {
  display: inline-flex;
  padding: 0 16px 9px;
  border-bottom: 1px solid transparent;
  justify-content: space-between;
  align-items: baseline;
}
.mdc-dialog--fullscreen .mdc-dialog__title {
  padding: 0;
  border-bottom: 0;
}
.mdc-dialog--fullscreen .mdc-dialog__close {
  width: 24px;
  height: 24px;
  padding: 0px;
}
.mdc-dialog--fullscreen.mdc-dialog--scrollable .mdc-dialog__actions {
  border-top: 1px solid transparent;
}

.mdc-dialog__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.mdc-dialog__content > :first-child {
  margin-top: 0;
}
.mdc-dialog__content > :last-child {
  margin-bottom: 0;
}

.mdc-dialog__title + .mdc-dialog__content {
  padding-top: 0;
}

.mdc-dialog--scrollable .mdc-dialog__title + .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 6px 0 0;
}

.mdc-dialog--scrollable .mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 0;
}

.mdc-dialog__actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  min-height: 52px;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent;
}
.mdc-dialog--stacked .mdc-dialog__actions {
  flex-direction: column;
  align-items: flex-end;
}

.mdc-dialog__button {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
  max-width: 100%;
  /* @noflip */
  text-align: right;
}
[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}
.mdc-dialog__button:first-child {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
.mdc-dialog[dir=rtl] .mdc-dialog__button, [dir=rtl] .mdc-dialog .mdc-dialog__button {
  /* @noflip */
  text-align: left;
}

.mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
  margin-top: 12px;
}

.mdc-dialog--open,
.mdc-dialog--opening,
.mdc-dialog--closing {
  display: flex;
}

.mdc-dialog--opening .mdc-dialog__scrim {
  transition: opacity 150ms linear;
}
.mdc-dialog--opening .mdc-dialog__container {
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-dialog--closing .mdc-dialog__scrim,
.mdc-dialog--closing .mdc-dialog__container {
  transition: opacity 75ms linear;
}
.mdc-dialog--closing .mdc-dialog__container {
  transform: none;
}

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1;
}
.mdc-dialog--open .mdc-dialog__container {
  transform: none;
  opacity: 1;
}

.mdc-dialog-scroll-lock {
  overflow: hidden;
}

.mdc-switch__thumb-underlay {
  /* @noflip */
  left: -14px;
  /* @noflip */
  right: initial;
  top: -17px;
  width: 48px;
  height: 48px;
}
[dir=rtl] .mdc-switch__thumb-underlay, .mdc-switch__thumb-underlay[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: -14px;
}

.mdc-switch__native-control {
  width: 64px;
  height: 48px;
}

.mdc-switch {
  display: inline-block;
  position: relative;
  outline: none;
  user-select: none;
}
.mdc-switch.mdc-switch--checked .mdc-switch__track {
  background-color: #26a69a;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #26a69a);
}
.mdc-switch.mdc-switch--checked .mdc-switch__thumb {
  background-color: #26a69a;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #26a69a);
  border-color: #26a69a;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #26a69a);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__track {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-theme-on-surface, #000);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  border-color: #fff;
  /* @alternate */
  border-color: var(--mdc-theme-surface, #fff);
}

.mdc-switch__native-control {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  position: absolute;
  top: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  pointer-events: auto;
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-switch__native-control, .mdc-switch__native-control[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}

.mdc-switch__track {
  box-sizing: border-box;
  width: 36px;
  height: 14px;
  border: 1px solid transparent;
  border-radius: 7px;
  opacity: 0.38;
  transition: opacity 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1), border-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-switch__thumb-underlay {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  transform: translateX(0);
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1), border-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-switch__thumb {
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 10px solid;
  border-radius: 50%;
  pointer-events: none;
  z-index: 1;
}

.mdc-switch--checked .mdc-switch__track {
  opacity: 0.54;
}
.mdc-switch--checked .mdc-switch__thumb-underlay {
  transform: translateX(16px);
}
[dir=rtl] .mdc-switch--checked .mdc-switch__thumb-underlay, .mdc-switch--checked .mdc-switch__thumb-underlay[dir=rtl] {
  transform: translateX(-16px);
}
.mdc-switch--checked .mdc-switch__native-control {
  transform: translateX(-16px);
}
[dir=rtl] .mdc-switch--checked .mdc-switch__native-control, .mdc-switch--checked .mdc-switch__native-control[dir=rtl] {
  transform: translateX(16px);
}

.mdc-switch--disabled {
  opacity: 0.38;
  pointer-events: none;
}
.mdc-switch--disabled .mdc-switch__thumb {
  border-width: 1px;
}
.mdc-switch--disabled .mdc-switch__native-control {
  cursor: default;
  pointer-events: none;
}

.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay::before, .mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay::after {
  background-color: #9e9e9e;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #9e9e9e);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:hover::before, .mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay.mdc-ripple-upgraded--background-focused::before, .mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-switch__thumb-underlay {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-switch__thumb-underlay::before, .mdc-switch__thumb-underlay::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-switch__thumb-underlay::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-switch__thumb-underlay::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-switch__thumb-underlay::before, .mdc-switch__thumb-underlay::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::before, .mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-switch__thumb-underlay::before, .mdc-switch__thumb-underlay::after {
  background-color: #26a69a;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-secondary, #26a69a));
}
.mdc-switch__thumb-underlay:hover::before, .mdc-switch__thumb-underlay.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--background-focused::before, .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.nty-dialog-warning {
  margin: 0 0 24px;
}

.nty-tf-public-url {
  margin: 24px 0 12px;
  width: stretch;
}

.nty-switch-share {
  margin: 14px 0 0 !important;
}
.nty-switch-share .nty-switch-share-label {
  margin: 0 12px 0 0;
  vertical-align: text-bottom;
}

.nty-tf-notebook-destroy {
  width: stretch;
}

.nty-tf-notebook {
  width: stretch;
}

.nty-tf-tag {
  width: stretch;
}

.nty-form-tag-temp-depth {
  display: none;
}

.mdc-drawer {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 0;
  /* @alternate */
  /* @noflip */
  border-top-right-radius: var(--mdc-shape-large, 0);
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @alternate */
  /* @noflip */
  border-bottom-right-radius: var(--mdc-shape-large, 0);
  /* @noflip */
  border-bottom-left-radius: 0;
  z-index: 6;
  width: 256px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100%;
  /* @noflip */
  border-right-width: 1px;
  /* @noflip */
  border-right-style: solid;
  overflow: hidden;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-drawer .mdc-drawer__title {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-drawer .mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-drawer__subtitle {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-drawer .mdc-list-item--activated .mdc-list-item__graphic {
  color: #2f4456;
}
.mdc-drawer .mdc-list-item--activated {
  color: rgba(47, 68, 86, 0.87);
}
[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 0;
  /* @alternate */
  /* @noflip */
  border-top-left-radius: var(--mdc-shape-large, 0);
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 0;
  /* @alternate */
  /* @noflip */
  border-bottom-left-radius: var(--mdc-shape-large, 0);
}
.mdc-drawer .mdc-list-item {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}
.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
  /* @noflip */
  margin-left: 256px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content, .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 256px;
}
[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /* @noflip */
  border-right-width: 0;
  /* @noflip */
  border-left-width: 1px;
  /* @noflip */
  border-right-style: none;
  /* @noflip */
  border-left-style: solid;
}
.mdc-drawer .mdc-list-item {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
  height: calc(48px - 2 * 4px);
  margin: 8px 8px;
  padding: 0 8px;
}
.mdc-drawer .mdc-list-item:nth-child(1) {
  margin-top: 2px;
}
.mdc-drawer .mdc-list-item:nth-last-child(1) {
  margin-bottom: 0;
}
.mdc-drawer .mdc-list-group__subheader {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  padding: 0 16px;
}
.mdc-drawer .mdc-list-group__subheader::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-drawer .mdc-list-divider {
  margin: 3px 0 4px;
}
.mdc-drawer .mdc-list-item__text,
.mdc-drawer .mdc-list-item__graphic {
  pointer-events: none;
}

.mdc-drawer--animate {
  transform: translateX(-100%);
}
[dir=rtl] .mdc-drawer--animate, .mdc-drawer--animate[dir=rtl] {
  transform: translateX(100%);
}

.mdc-drawer--opening {
  transform: translateX(0);
  transition-duration: 250ms;
}
[dir=rtl] .mdc-drawer--opening, .mdc-drawer--opening[dir=rtl] {
  transform: translateX(0);
}

.mdc-drawer--closing {
  transform: translateX(-100%);
  transition-duration: 200ms;
}
[dir=rtl] .mdc-drawer--closing, .mdc-drawer--closing[dir=rtl] {
  transform: translateX(100%);
}

.mdc-drawer__header {
  flex-shrink: 0;
  box-sizing: border-box;
  min-height: 64px;
  padding: 0 16px 4px;
}

.mdc-drawer__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-drawer__title::before {
  display: inline-block;
  width: 0;
  height: 36px;
  content: "";
  vertical-align: 0;
}
.mdc-drawer__title::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-drawer__subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: 0;
}
.mdc-drawer__subtitle::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer__content {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdc-drawer--dismissible {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute;
}
[dir=rtl] .mdc-drawer--dismissible, .mdc-drawer--dismissible[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}
.mdc-drawer--dismissible.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-app-content {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
  position: relative;
}
[dir=rtl] .mdc-drawer-app-content, .mdc-drawer-app-content[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

.mdc-drawer--modal {
  /* @alternate */
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: fixed;
}
.mdc-drawer--modal + .mdc-drawer-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
[dir=rtl] .mdc-drawer--modal, .mdc-drawer--modal[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}
.mdc-drawer--modal.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-drawer--open + .mdc-drawer-scrim {
  display: block;
}
.mdc-drawer--animate + .mdc-drawer-scrim {
  opacity: 0;
}
.mdc-drawer--opening + .mdc-drawer-scrim {
  transition-duration: 250ms;
  opacity: 1;
}
.mdc-drawer--closing + .mdc-drawer-scrim {
  transition-duration: 200ms;
  opacity: 0;
}

.mdc-drawer.nty-drawer {
  width: 240px;
}
.mdc-drawer.nty-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
  /* @noflip */
  margin-left: 240px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-drawer.nty-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content, .mdc-drawer.nty-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 240px;
}

.mdc-drawer__header.nty-drawer-header {
  background-color: #fafafa;
  max-height: 48px;
  min-height: 48px;
  padding: 0;
}
.mdc-drawer__header.nty-drawer-header .mdc-list.nty-menu-toggler-list {
  padding: 0;
}
.mdc-drawer__header.nty-drawer-header .mdc-list.nty-menu-toggler-list .mdc-list-item.nty-menu-account-toggler {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.mdc-menu.nty-menu-account {
  width: 232px;
}
.mdc-menu.nty-menu-account .mdc-list-item {
  height: 40px;
}

.mdc-list-item.nty-menu-account-list-item {
  border-radius: 0;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  margin: 0;
  padding: 0 16px;
}
.mdc-list-item.nty-menu-account-list-item .mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.87);
}

.nty-tf-drawer-search {
  height: 40px;
  margin: 12px 8px;
  width: stretch;
}
.nty-tf-drawer-search:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.nty-tf-drawer-search .mdc-text-field__input {
  height: 100%;
}
.nty-tf-drawer-search .mdc-floating-label {
  display: none;
}
.nty-tf-drawer-search::before {
  display: none;
}
@supports (-webkit-hyphens: none) {
  .nty-tf-drawer-search .mdc-text-field__affix {
    align-items: center;
    align-self: center;
    display: inline-flex;
    height: 100%;
  }
}
.nty-tf-drawer-search .mdc-floating-label {
  display: block;
  font-size: 14px;
}

.mdc-button.nty-button-new-note {
  height: 32px;
  margin-top: 0;
  margin-bottom: 0;
  margin: 2px 8px 0;
  width: stretch;
}
.mdc-button.nty-button-new-note:not(:disabled) {
  background-color: #26a69a;
}
.mdc-button.nty-button-new-note .mdc-button__touch {
  display: none;
}

.mdc-list.nty-drawer-list {
  padding: 12px 0;
}
.mdc-list.nty-drawer-list .mdc-list-item {
  height: 32px;
}

.mdc-typography {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif);
}

.mdc-typography--headline1 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline1-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 6rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline1-font-size, 6rem);
  line-height: 6rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline1-line-height, 6rem);
  font-weight: 300;
  /* @alternate */
  font-weight: var(--mdc-typography-headline1-font-weight, 300);
  letter-spacing: -0.015625em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline1-letter-spacing, -0.015625em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline1-text-transform, inherit);
}

.mdc-typography--headline2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline2-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 3.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline2-font-size, 3.75rem);
  line-height: 3.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline2-line-height, 3.75rem);
  font-weight: 300;
  /* @alternate */
  font-weight: var(--mdc-typography-headline2-font-weight, 300);
  letter-spacing: -0.0083333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline2-letter-spacing, -0.0083333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline2-text-transform, inherit);
}

.mdc-typography--headline3 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline3-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 3rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline3-font-size, 3rem);
  line-height: 3.125rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline3-line-height, 3.125rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline3-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline3-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline3-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline3-text-transform, inherit);
}

.mdc-typography--headline4 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline4-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 2.125rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline4-font-size, 2.125rem);
  line-height: 2.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline4-line-height, 2.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline4-font-weight, 400);
  letter-spacing: 0.0073529412em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline4-letter-spacing, 0.0073529412em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline4-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline4-text-transform, inherit);
}

.mdc-typography--headline5 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline5-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1.5rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline5-font-size, 1.5rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline5-line-height, 2rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-headline5-font-weight, 400);
  letter-spacing: normal;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline5-letter-spacing, normal);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline5-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline5-text-transform, inherit);
}

.mdc-typography--headline6 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
}

.mdc-typography--subtitle1 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle1-font-size, 1rem);
  line-height: 1.75rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle1-line-height, 1.75rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: 0.009375em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, 0.009375em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle1-text-transform, inherit);
}

.mdc-typography--subtitle2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-subtitle2-font-size, 0.875rem);
  line-height: 1.375rem;
  /* @alternate */
  line-height: var(--mdc-typography-subtitle2-line-height, 1.375rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: 0.0071428571em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, 0.0071428571em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-subtitle2-text-transform, inherit);
}

.mdc-typography--body1 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1rem;
  /* @alternate */
  font-size: var(--mdc-typography-body1-font-size, 1rem);
  line-height: 1.5rem;
  /* @alternate */
  line-height: var(--mdc-typography-body1-line-height, 1.5rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: 0.03125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body1-letter-spacing, 0.03125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body1-text-transform, inherit);
}

.mdc-typography--body2 {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
}

.mdc-typography--caption {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-caption-font-size, 0.75rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-caption-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: 0.0333333333em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-caption-letter-spacing, 0.0333333333em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-caption-text-transform, inherit);
}

.mdc-typography--button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
}

.mdc-typography--overline {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-overline-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.75rem;
  /* @alternate */
  font-size: var(--mdc-typography-overline-font-size, 0.75rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-overline-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-overline-font-weight, 500);
  letter-spacing: 0.1666666667em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-overline-letter-spacing, 0.1666666667em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-overline-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-overline-text-transform, uppercase);
}

.mdc-top-app-bar {
  background-color: #2f4456;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #2f4456);
  color: white;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  z-index: 4;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-on-primary, #fff));
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:hover::before, .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-surface--hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before, .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-top-app-bar__row {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
}
.mdc-top-app-bar__section {
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  padding: 8px 12px;
  z-index: 1;
}
.mdc-top-app-bar__section--align-start {
  justify-content: flex-start;
  order: -1;
}
.mdc-top-app-bar__section--align-end {
  justify-content: flex-end;
  order: 1;
}
.mdc-top-app-bar__title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 1.25rem;
  /* @alternate */
  font-size: var(--mdc-typography-headline6-font-size, 1.25rem);
  line-height: 2rem;
  /* @alternate */
  line-height: var(--mdc-typography-headline6-line-height, 2rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: 0.0125em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, 0.0125em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-headline6-text-transform, inherit);
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}
[dir=rtl] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 20px;
}

.mdc-top-app-bar--short-collapsed {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 24px;
  /* @noflip */
  border-bottom-left-radius: 0;
}
[dir=rtl] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir=rtl] {
  /* @noflip */
  border-top-left-radius: 0;
  /* @noflip */
  border-top-right-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 0;
  /* @noflip */
  border-bottom-left-radius: 24px;
}

.mdc-top-app-bar--short {
  top: 0;
  /* @noflip */
  right: auto;
  /* @noflip */
  left: 0;
  width: 100%;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir=rtl] {
  /* @noflip */
  right: 0;
  /* @noflip */
  left: auto;
}
.mdc-top-app-bar--short .mdc-top-app-bar__row {
  height: 56px;
}
.mdc-top-app-bar--short .mdc-top-app-bar__section {
  padding: 4px;
}
.mdc-top-app-bar--short .mdc-top-app-bar__title {
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.mdc-top-app-bar--short-collapsed {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 56px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
  display: none;
}
.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
  transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px;
}
.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px;
}
.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px;
}
.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px;
}
.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  align-self: flex-end;
  padding-bottom: 2px;
}
.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item,
.mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  align-self: flex-start;
}

.mdc-top-app-bar--fixed {
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--fixed-scrolled {
  /* @alternate */
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px;
}
.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px;
}
.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  padding-bottom: 9px;
}
[dir=rtl] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 20px;
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px;
}

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px;
}

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px;
}

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px;
}

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px;
}

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px;
  }
  .mdc-top-app-bar__section {
    padding: 4px;
  }
  .mdc-top-app-bar--short {
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .mdc-top-app-bar--short-collapsed {
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px;
  }
  [dir=rtl] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir=rtl] {
    /* @noflip */
    padding-left: 12px;
    /* @noflip */
    padding-right: 0;
  }
  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px;
  }
  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px;
  }
}
.mdc-top-app-bar.nty-layout-default-top-app-bar {
  background-color: #fafafa;
}
.mdc-top-app-bar.nty-layout-default-top-app-bar .mdc-top-app-bar__title {
  padding-left: 12px;
}
.mdc-top-app-bar.nty-layout-default-top-app-bar .mdc-top-app-bar__title a {
  color: #2f4456;
}

.mdc-button.nty-button-sign-up {
  margin-right: 8px;
}
.mdc-button.nty-button-sign-up:not(:disabled) {
  background-color: #26a69a;
}
.nty-layout-default-page-fixed {
  margin: 0 auto;
  max-width: 1020px;
}

.mdc-top-app-bar.nty-layout-sidebar-top-app-bar {
  background-color: #fafafa;
  color: #2f4456;
}
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__action-item,
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: #2f4456;
}
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__action-item::after,
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__navigation-icon::before,
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__navigation-icon::after {
  background-color: #2f4456;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #2f4456);
}
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__action-item:hover::before, .mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-surface--hover::before,
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before,
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-surface--hover::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before, .mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before,
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before,
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-top-app-bar.nty-layout-sidebar-top-app-bar .mdc-top-app-bar__title {
  padding-left: 12px;
}

/* BASICS */
.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
  direction: ltr;
}

/* PADDING */
.CodeMirror-lines {
  padding: 4px 0; /* Vertical padding around content */
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px; /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: white; /* The little square between H and V scrollbars */
}

/* GUTTER */
.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}

.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}

.CodeMirror-guttermarker {
  color: black;
}

.CodeMirror-guttermarker-subtle {
  color: #999;
}

/* CURSOR */
.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}

/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7;
}

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-fat-cursor .CodeMirror-line::selection,
.cm-fat-cursor .CodeMirror-line > span::selection,
.cm-fat-cursor .CodeMirror-line > span > span::selection {
  background: transparent;
}

.cm-fat-cursor .CodeMirror-line::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span::-moz-selection,
.cm-fat-cursor .CodeMirror-line > span > span::-moz-selection {
  background: transparent;
}

.cm-fat-cursor {
  caret-color: transparent;
}

@-moz-keyframes blink {
  50% {
    background-color: transparent;
  }
}
@-webkit-keyframes blink {
  50% {
    background-color: transparent;
  }
}
@keyframes blink {
  50% {
    background-color: transparent;
  }
}
/* Can style cursor different in overwrite (non-insert) mode */
.cm-tab {
  display: inline-block;
  text-decoration: inherit;
}

.CodeMirror-rulers {
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  bottom: 0;
  overflow: hidden;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0;
  bottom: 0;
  position: absolute;
}

/* DEFAULT THEME */
.cm-s-default .cm-header {
  color: blue;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header, .cm-strong {
  font-weight: bold;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}

.cm-s-default .cm-atom {
  color: #219;
}

.cm-s-default .cm-number {
  color: #164;
}

.cm-s-default .cm-def {
  color: #00f;
}

.cm-s-default .cm-variable-2 {
  color: #05a;
}

.cm-s-default .cm-variable-3, .cm-s-default .cm-type {
  color: #085;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-string {
  color: #a11;
}

.cm-s-default .cm-string-2 {
  color: #f50;
}

.cm-s-default .cm-meta {
  color: #555;
}

.cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-bracket {
  color: #997;
}

.cm-s-default .cm-tag {
  color: #170;
}

.cm-s-default .cm-attribute {
  color: #00c;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error {
  color: #f00;
}

.cm-invalidchar {
  color: #f00;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

/* Default styles for common addons */
div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}

.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3);
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

/* STOP */
/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */
.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}

.CodeMirror-scroll {
  overflow: scroll !important; /* Things will break if this is overridden */
  /* 50px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  height: 100%;
  outline: none; /* Prevent dragging from highlighting the element */
  position: relative;
  z-index: 0;
}

.CodeMirror-sizer {
  position: relative;
  border-right: 50px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
  outline: none;
}

.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}

.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}

.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3;
}

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -50px;
}

.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}

.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
}

.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}

.CodeMirror-gutter-wrapper ::selection {
  background-color: transparent;
}

.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: transparent;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px; /* prevents collapsing before first draw */
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  /* Reset some styles that the rest of the page might have set */
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
}

.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px; /* Force widget margins to stay inside of the container */
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.CodeMirror-code {
  outline: none;
}

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}

.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}

div.CodeMirror-dragcursors {
  visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, 0.4);
}

/* Used to force a border model for a node */
.cm-force-border {
  padding-right: 0.1px;
}

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}
/* See issue #2901 */
.cm-tab-wrap-hack:after {
  content: "";
}

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
  background: none;
}

.cm-s-eclipse span.cm-meta {
  color: #FF1717;
}

.cm-s-eclipse span.cm-keyword {
  line-height: 1em;
  font-weight: bold;
  color: #7F0055;
}

.cm-s-eclipse span.cm-atom {
  color: #219;
}

.cm-s-eclipse span.cm-number {
  color: #164;
}

.cm-s-eclipse span.cm-def {
  color: #00f;
}

.cm-s-eclipse span.cm-variable {
  color: black;
}

.cm-s-eclipse span.cm-variable-2 {
  color: #0000C0;
}

.cm-s-eclipse span.cm-variable-3, .cm-s-eclipse span.cm-type {
  color: #0000C0;
}

.cm-s-eclipse span.cm-property {
  color: black;
}

.cm-s-eclipse span.cm-operator {
  color: black;
}

.cm-s-eclipse span.cm-comment {
  color: #3F7F5F;
}

.cm-s-eclipse span.cm-string {
  color: #2A00FF;
}

.cm-s-eclipse span.cm-string-2 {
  color: #f50;
}

.cm-s-eclipse span.cm-qualifier {
  color: #555;
}

.cm-s-eclipse span.cm-builtin {
  color: #30a;
}

.cm-s-eclipse span.cm-bracket {
  color: #cc7;
}

.cm-s-eclipse span.cm-tag {
  color: #170;
}

.cm-s-eclipse span.cm-attribute {
  color: #00c;
}

.cm-s-eclipse span.cm-link {
  color: #219;
}

.cm-s-eclipse span.cm-error {
  color: #f00;
}

.cm-s-eclipse .CodeMirror-activeline-background {
  background: #e8f2ff;
}

.cm-s-eclipse .CodeMirror-matchingbracket {
  outline: 1px solid grey;
  color: black !important;
}

:root {
  --tagify-dd-color-primary: rgb(53,149,246);
  --tagify-dd-text-color: black;
  --tagify-dd-bg-color: white;
  --tagify-dd-item-pad: .3em .5em;
  --tagify-dd-max-height: 300px;
}

.tagify {
  --tags-disabled-bg: #F1F1F1;
  --tags-border-color: #DDD;
  --tags-hover-border-color: #CCC;
  --tags-focus-border-color: #3595f6;
  --tag-border-radius: 3px;
  --tag-bg: #E5E5E5;
  --tag-hover: #D3E2E2;
  --tag-text-color: black;
  --tag-text-color--edit: black;
  --tag-pad: 0.3em 0.5em;
  --tag-inset-shadow-size: 1.1em;
  --tag-invalid-color: #D39494;
  --tag-invalid-bg: rgba(211, 148, 148, 0.5);
  --tag--min-width: 1ch;
  --tag--max-width: 100%;
  --tag-hide-transition: 0.3s;
  --tag-remove-bg: rgba(211, 148, 148, 0.3);
  --tag-remove-btn-color: black;
  --tag-remove-btn-bg: none;
  --tag-remove-btn-bg--hover: rgb(199.1112582781, 119.0887417219, 119.0887417219);
  --input-color: inherit;
  --placeholder-color: rgba(0, 0, 0, 0.4);
  --placeholder-color-focus: rgba(0, 0, 0, 0.25);
  --loader-size: .8em;
  --readonly-striped: 1;
  display: inline-flex;
  align-items: flex-start;
  flex-wrap: wrap;
  border: 1px solid var(--tags-border-color);
  padding: 0;
  line-height: 0;
  outline: none;
  position: relative;
  box-sizing: border-box;
  transition: 0.1s;
}
@keyframes tags--bump {
  30% {
    transform: scale(1.2);
  }
}
@keyframes rotateLoader {
  to {
    transform: rotate(1turn);
  }
}
.tagify:has([contenteditable=true]) {
  cursor: text;
}
.tagify:hover:not(.tagify--focus):not(.tagify--invalid) {
  --tags-border-color: var(--tags-hover-border-color);
}
.tagify[disabled] {
  background: var(--tags-disabled-bg);
  filter: saturate(0);
  opacity: 0.5;
  pointer-events: none;
}
.tagify[readonly].tagify--select, .tagify[disabled].tagify--select {
  pointer-events: none;
}
.tagify[readonly]:not(.tagify--mix):not(.tagify--select), .tagify[disabled]:not(.tagify--mix):not(.tagify--select) {
  cursor: default;
}
.tagify[readonly]:not(.tagify--mix):not(.tagify--select) > .tagify__input, .tagify[disabled]:not(.tagify--mix):not(.tagify--select) > .tagify__input {
  visibility: hidden;
  width: 0;
  margin: 5px 0;
}
.tagify[readonly]:not(.tagify--mix):not(.tagify--select) .tagify__tag > div, .tagify[disabled]:not(.tagify--mix):not(.tagify--select) .tagify__tag > div {
  padding: var(--tag-pad);
}
.tagify[readonly]:not(.tagify--mix):not(.tagify--select) .tagify__tag > div::before, .tagify[disabled]:not(.tagify--mix):not(.tagify--select) .tagify__tag > div::before {
  animation: readonlyStyles 1s calc(-1s * (var(--readonly-striped) - 1)) paused;
}
@keyframes readonlyStyles {
  0% {
    background: linear-gradient(45deg, var(--tag-bg) 25%, transparent 25%, transparent 50%, var(--tag-bg) 50%, var(--tag-bg) 75%, transparent 75%, transparent) 0/5px 5px;
    box-shadow: none;
    filter: brightness(0.95);
  }
}
.tagify[readonly] .tagify__tag__removeBtn, .tagify[disabled] .tagify__tag__removeBtn {
  display: none;
}
.tagify--loading .tagify__input > br:last-child {
  display: none;
}
.tagify--loading .tagify__input::before {
  content: none;
}
.tagify--loading .tagify__input::after {
  content: "";
  vertical-align: middle;
  opacity: 1;
  width: 0.7em;
  height: 0.7em;
  width: var(--loader-size);
  height: var(--loader-size);
  min-width: 0;
  border: 3px solid;
  border-color: #EEE #BBB #888 transparent;
  border-radius: 50%;
  animation: rotateLoader 0.4s infinite linear;
  content: "" !important;
  margin: -2px 0 -2px 0.5em;
}
.tagify--loading .tagify__input:empty::after {
  margin-left: 0;
}
.tagify + input,
.tagify + textarea {
  position: absolute !important;
  left: -9999em !important;
  transform: scale(0) !important;
}
.tagify__tag {
  display: inline-flex;
  align-items: center;
  max-width: var(--tag--max-width);
  margin-inline: 5px 0;
  margin-block: 5px;
  position: relative;
  z-index: 1;
  outline: none;
  line-height: normal;
  cursor: default;
  transition: 0.13s ease-out;
}
.tagify__tag > div {
  display: flex;
  flex: 1;
  vertical-align: top;
  box-sizing: border-box;
  max-width: 100%;
  padding: var(--tag-pad);
  color: var(--tag-text-color);
  line-height: inherit;
  border-radius: var(--tag-border-radius);
  white-space: nowrap;
  transition: 0.13s ease-out;
}
.tagify__tag > div > * {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top;
  min-width: var(--tag--min-width);
  max-width: var(--tag--max-width);
  transition: 0.8s ease, 0.1s color;
}
.tagify__tag > div > *[contenteditable] {
  display: block;
  outline: none;
  user-select: text;
  cursor: text;
  margin: -2px;
  padding: 2px;
  max-width: 350px;
}
.tagify__tag > div > *:only-child {
  width: 100%;
}
.tagify__tag > div::before {
  content: "";
  position: absolute;
  border-radius: inherit;
  inset: var(--tag-bg-inset, 0);
  z-index: -1;
  pointer-events: none;
  transition: 120ms ease;
  animation: tags--bump 0.3s ease-out 1;
  box-shadow: 0 0 0 var(--tag-inset-shadow-size) var(--tag-bg) inset;
}
.tagify__tag:hover:not([readonly]) div::before, .tagify__tag:focus div::before {
  --tag-bg-inset: -2.5px;
  --tag-bg: var(--tag-hover);
}
.tagify__tag--loading {
  pointer-events: none;
}
.tagify__tag--loading .tagify__tag__removeBtn {
  display: none;
}
.tagify__tag--loading::after {
  --loader-size: .4em;
  content: "";
  vertical-align: middle;
  opacity: 1;
  width: 0.7em;
  height: 0.7em;
  width: var(--loader-size);
  height: var(--loader-size);
  min-width: 0;
  border: 3px solid;
  border-color: #EEE #BBB #888 transparent;
  border-radius: 50%;
  animation: rotateLoader 0.4s infinite linear;
  margin: 0 0.5em 0 -0.1em;
}
.tagify__tag--flash div::before {
  animation: none;
}
.tagify__tag--hide {
  width: 0 !important;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  opacity: 0;
  transform: scale(0);
  transition: var(--tag-hide-transition);
  pointer-events: none;
}
.tagify__tag--hide > div > * {
  white-space: nowrap;
}
.tagify__tag.tagify--noAnim > div::before {
  animation: none;
}
.tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div > span {
  opacity: 0.5;
}
.tagify__tag.tagify--notAllowed:not(.tagify__tag--editable) div::before {
  --tag-bg: var(--tag-invalid-bg);
  transition: 0.2s;
}
.tagify__tag[readonly] .tagify__tag__removeBtn {
  display: none;
}
.tagify__tag[readonly] > div::before {
  animation: readonlyStyles 1s calc(-1s * (var(--readonly-striped) - 1)) paused;
}
@keyframes readonlyStyles {
  0% {
    background: linear-gradient(45deg, var(--tag-bg) 25%, transparent 25%, transparent 50%, var(--tag-bg) 50%, var(--tag-bg) 75%, transparent 75%, transparent) 0/5px 5px;
    box-shadow: none;
    filter: brightness(0.95);
  }
}
.tagify__tag--editable > div {
  color: var(--tag-text-color--edit);
}
.tagify__tag--editable > div::before {
  box-shadow: 0 0 0 2px var(--tag-hover) inset !important;
}
.tagify__tag--editable > .tagify__tag__removeBtn {
  pointer-events: none;
  opacity: 0;
  transform: translateX(100%) translateX(5px);
}
.tagify__tag--editable.tagify--invalid > div::before {
  box-shadow: 0 0 0 2px var(--tag-invalid-color) inset !important;
}
.tagify__tag__removeBtn {
  order: 5;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
  font: 14px/1 Arial;
  background: var(--tag-remove-btn-bg);
  color: var(--tag-remove-btn-color);
  width: 14px;
  height: 14px;
  margin-inline: auto 4.6666666667px;
  overflow: hidden;
  transition: 0.2s ease-out;
}
.tagify__tag__removeBtn::after {
  content: "×";
  transition: 0.3s, color 0s;
}
.tagify__tag__removeBtn:hover {
  color: white;
  background: var(--tag-remove-btn-bg--hover);
}
.tagify__tag__removeBtn:hover + div > span {
  opacity: 0.5;
}
.tagify__tag__removeBtn:hover + div::before {
  box-shadow: 0 0 0 var(--tag-inset-shadow-size) var(--tag-remove-bg, rgba(211, 148, 148, 0.3)) inset !important;
  transition: box-shadow 0.2s;
}
.tagify:not(.tagify--mix) .tagify__input br {
  display: none;
}
.tagify:not(.tagify--mix) .tagify__input * {
  display: inline;
  white-space: nowrap;
}
.tagify__input {
  flex-grow: 1;
  display: inline-block;
  min-width: 110px;
  margin: 5px;
  padding: var(--tag-pad);
  line-height: normal;
  position: relative;
  white-space: pre-wrap;
  color: var(--input-color);
  box-sizing: inherit;
  overflow: hidden;
  /* Seems firefox newer versions don't need this any more
  @supports ( -moz-appearance:none ){
      &::before{
          line-height: inherit;
          position:relative;
      }
  }
  */
}
@-moz-document url-prefix() {}
.tagify__input:focus {
  outline: none;
}
.tagify__input:focus::before {
  transition: 0.2s ease-out;
  opacity: 0;
  transform: translatex(6px);
  /* ALL MS BROWSERS: hide placeholder (on focus) otherwise the caret is placed after it, which is weird */
  /* IE Edge 12+ CSS styles go here */
}
@supports (-ms-ime-align: auto) {
  .tagify__input:focus::before {
    display: none;
  }
}
.tagify__input:focus:empty::before {
  transition: 0.2s ease-out;
  opacity: 1;
  transform: none;
  color: rgba(0, 0, 0, 0.25);
  color: var(--placeholder-color-focus);
}
@-moz-document url-prefix() {
  .tagify__input:focus:empty::after {
    display: none;
  }
}
.tagify__input::before {
  content: attr(data-placeholder);
  width: 100%;
  height: 100%;
  margin: auto 0;
  z-index: 1;
  color: var(--placeholder-color);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  position: absolute;
}
.tagify__input::after {
  content: attr(data-suggest);
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  min-width: calc(100% - 1.5em);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre; /* allows spaces at the beginning */
  color: var(--tag-text-color);
  opacity: 0.3;
  pointer-events: none;
  max-width: 100px;
}
.tagify__input .tagify__tag {
  margin: 0 1px;
}
.tagify--mix {
  display: block;
}
.tagify--mix .tagify__input {
  padding: 5px;
  margin: 0;
  width: 100%;
  height: 100%;
  line-height: 1.5;
  display: block;
}
.tagify--mix .tagify__input::before {
  height: auto;
  display: none;
  line-height: inherit;
}
.tagify--mix .tagify__input::after {
  content: none;
}
.tagify--select {
  cursor: default;
}
.tagify--select::after {
  content: ">";
  opacity: 0.5;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  font: 16px monospace;
  line-height: 8px;
  height: 8px;
  pointer-events: none;
  transform: translate(-150%, -50%) scaleX(1.2) rotate(90deg);
  transition: 0.2s ease-in-out;
}
.tagify--select[aria-expanded=true]::after {
  transform: translate(-150%, -50%) rotate(270deg) scaleY(1.2);
}
.tagify--select[aria-expanded=true] .tagify__tag__removeBtn {
  pointer-events: none;
  opacity: 0;
  transform: translateX(100%) translateX(5px);
}
.tagify--select .tagify__tag {
  flex: 1;
  max-width: none;
  margin-inline-end: 2em;
  margin-block: 0;
  padding-block: 5px;
  cursor: text;
}
.tagify--select .tagify__tag div::before {
  display: none;
}
.tagify--select .tagify__tag + .tagify__input {
  display: none;
}
.tagify--empty .tagify__input::before {
  transition: 0.2s ease-out;
  opacity: 1;
  transform: none;
  display: inline-block;
  width: auto;
}
.tagify--mix .tagify--empty .tagify__input::before {
  display: inline-block;
}
.tagify--focus {
  --tags-border-color: var(--tags-focus-border-color);
  transition: 0s;
}
.tagify--invalid {
  --tags-border-color: #D39494;
}
.tagify__dropdown {
  position: absolute;
  z-index: 9999;
  transform: translateY(-1px);
  border-top: 1px solid var(--tagify-dd-color-primary);
  overflow: hidden;
}
.tagify__dropdown[dir=rtl] {
  transform: translate(-100%, -1px);
}
.tagify__dropdown[placement=top] {
  margin-top: 0;
  transform: translateY(-100%);
}
.tagify__dropdown[placement=top] .tagify__dropdown__wrapper {
  border-top-width: 1.1px;
  border-bottom-width: 0;
}
.tagify__dropdown[position=text] {
  box-shadow: 0 0 0 3px rgba(var(--tagify-dd-color-primary), 0.1);
  font-size: 0.9em;
}
.tagify__dropdown[position=text] .tagify__dropdown__wrapper {
  border-width: 1px;
}
.tagify__dropdown__wrapper {
  scroll-behavior: auto;
  max-height: var(--tagify-dd-max-height);
  overflow: hidden;
  overflow-x: hidden;
  color: var(--tagify-dd-text-color);
  background: var(--tagify-dd-bg-color);
  border: 1px solid;
  border-color: var(--tagify-dd-color-primary);
  border-bottom-width: 1.5px;
  border-top-width: 0;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
  transition: 0.3s cubic-bezier(0.5, 0, 0.3, 1), transform 0.15s;
  animation: dd-wrapper-show 0s 0.3s forwards;
}
@keyframes dd-wrapper-show {
  to {
    overflow-y: auto;
  }
}
.tagify__dropdown__header:empty {
  display: none;
}
.tagify__dropdown__footer {
  display: inline-block;
  margin-top: 0.5em;
  padding: var(--tagify-dd-item-pad);
  font-size: 0.7em;
  font-style: italic;
  opacity: 0.5;
}
.tagify__dropdown__footer:empty {
  display: none;
}
.tagify__dropdown--initial .tagify__dropdown__wrapper {
  max-height: 20px;
  transform: translateY(-1em);
}
.tagify__dropdown--initial[placement=top] .tagify__dropdown__wrapper {
  transform: translateY(2em);
}
.tagify__dropdown__item {
  box-sizing: border-box;
  padding: var(--tagify-dd-item-pad);
  margin: 1px;
  white-space: pre-wrap;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  outline: none;
  max-height: 60px;
  max-width: 100%;
  line-height: normal;
  position: relative;
  /* custom hidden transition effect is needed for horizontal-layout suggestions */
}
.tagify__dropdown__item--active {
  background: var(--tagify-dd-color-primary);
  color: white;
}
.tagify__dropdown__item:active {
  filter: brightness(105%);
}
.tagify__dropdown__item--hidden {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0 1px;
  pointer-events: none;
  overflow: hidden;
  max-height: 0;
  transition: var(--tagify-dd-item--hidden-duration, 0.3s) !important;
}
.tagify__dropdown__item--hidden > * {
  transform: translateY(-100%);
  opacity: 0;
  transition: inherit;
}
.tagify__dropdown__item--selected::before {
  content: "✓";
  font-family: monospace;
  position: absolute;
  inset-inline-start: 6px;
  text-indent: 0;
  line-height: 1.1;
}
.tagify__dropdown:has(.tagify__dropdown__item--selected) .tagify__dropdown__item {
  text-indent: 1em;
}

.nty-container-note-form {
  margin: 8px 18px 0;
}

.nty-tf-note {
  width: stretch;
}

.nty-form-note-temp-tags {
  display: none;
}

.tagify {
  --tags-border-color: rgba(0, 0, 0, 0.42);
  --tag-bg: #f5f5f5;
  --tag-hover: #dcdcdc;
  --tag-text-color: #4a4a4a;
  --tag-pad: 0.35em 0.7em;
  --tag-remove-bg: #dcdcdc;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  padding: 2px 0 4px;
}
.tagify:hover {
  border-bottom: 1px solid rgba(0, 0, 0, 0.73);
}
.tagify.tagify--focus {
  border-bottom: 2px solid rgba(0, 0, 0, 0.73);
  padding: 2px 0 3px;
}
.tagify__dropdown {
  color: #4a4a4a;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  transform: none;
}
.tagify__dropdown__wrapper {
  background: #f5f5f5;
  border: 1px solid rgba(0, 0, 0, 0.73);
}
.tagify__dropdown__item {
  border-radius: 4px;
}
.tagify__dropdown__item--active {
  background: #dcdcdc;
  color: #4a4a4a;
}
.tagify__tag > div {
  border-radius: 4px;
}
.tagify__tag:hover:not([readonly]) div::before {
  inset: 0;
}

.CodeMirror {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1rem;
  height: auto;
  margin: 4px 0;
  padding: 10px;
}
.CodeMirror.CodeMirror-focused {
  border: 2px solid rgba(0, 0, 0, 0.73);
  padding: 9px;
}

.CodeMirror-scroll {
  min-height: calc(100vh - 360px);
}

.mdc-list.nty-list-notes {
  padding: 0;
}
.mdc-list.nty-list-notes .mdc-list-item.nty-list-notes-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-left: 18px;
}

.mdc-menu.nty-menu-note {
  width: 168px;
}
.mdc-menu.nty-menu-note .mdc-list-item {
  height: 40px;
}

.nty-container-note-private,
.nty-container-note-private--reading {
  margin: 0 18px 24px;
}
.nty-container-note-private--reading {
  margin: 0 auto;
  max-width: 760px;
  padding: 0 18px 24px;
}

.mdc-list.nty-list-note-private-controls {
  padding: 0;
}
.mdc-list.nty-list-note-private-controls .mdc-list-item.nty-list-note-private-controls-item {
  padding-left: 18px;
}

@media (max-width: 760px) {
  .nty-note-readability-toggler {
    display: none;
  }
}
.nty-container-note-public {
  margin: 0 12px;
}

.mdc-list.nty-list-note-public-controls .mdc-list-item.nty-list-note-public-controls-item {
  padding: 0;
}

.nty-container-password-reset-complete {
  margin: 0 auto;
  max-width: 400px;
}

.nty-tf-password-reset-complete {
  height: 48px;
  width: stretch;
}
.nty-tf-password-reset-complete .mdc-floating-label--float-above {
  transform: translateY(-33.25px) scale(1);
}
.nty-tf-password-reset-complete .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.nty-tf-password-reset-complete.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-password-reset-complete .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-30.75px) scale(0.75);
}
.nty-tf-password-reset-complete.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-password-reset-complete .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.nty-tf-password-reset-complete .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-48px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-48px {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
}

.mdc-button.nty-button-submit-password-reset-complete {
  margin: 8px 0 24px;
  width: stretch;
}

.nty-container-password-reset-request {
  margin: 0 auto;
  max-width: 400px;
}

.nty-tf-password-reset-request {
  height: 48px;
  width: stretch;
}
.nty-tf-password-reset-request .mdc-floating-label--float-above {
  transform: translateY(-33.25px) scale(1);
}
.nty-tf-password-reset-request .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.nty-tf-password-reset-request.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-password-reset-request .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-30.75px) scale(0.75);
}
.nty-tf-password-reset-request.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-password-reset-request .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.nty-tf-password-reset-request .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-48px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-48px {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
}

.mdc-button.nty-button-submit-password-reset-request {
  margin: 8px 0 24px;
  width: stretch;
}

.nty-container-revisions {
  margin: 0 auto;
  max-width: 600px;
}

.nty-container-revision {
  margin: 0 auto;
  max-width: 760px;
}
.nty-container-revision .nty-revision-title {
  margin: 24px 0;
}
.nty-container-revision .nty-revision-content {
  margin: 0 0 24px;
}

.diff {
  border-radius: 4px;
  overflow-x: auto;
}
.diff ul {
  display: table;
  list-style: none;
  margin: 0;
  overflow: auto;
  padding: 0;
  width: 100%;
}
.diff del,
.diff ins {
  display: block;
  text-decoration: none;
}
.diff li {
  display: table-row;
  height: 1em;
  margin: 0;
  padding: 0;
}
.diff li.unchanged {
  background: #f6f8fa;
}
.diff li.ins {
  background: #e6ffed;
  color: #24292e;
}
.diff li.del {
  background: #ffeef0;
  color: #24292e;
}
.diff li.diff-comment {
  display: none;
}
.diff li.diff-block-info {
  background: none repeat scroll 0 0 #f1f8ff;
}
.diff del,
.diff ins,
.diff span {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  margin: 0 8px;
  white-space: pre;
}
.diff del strong {
  background: #fdb8c0;
  font-weight: normal;
}
.diff ins strong {
  background: #acf2bd;
  font-weight: normal;
}

.nty-search-title {
  margin: 8px 0 8px 18px;
}

.mdc-list.nty-list-search {
  padding: 0;
}
.mdc-list.nty-list-search .mdc-list-item.nty-list-search-item {
  padding-left: 18px;
}

.nty-container-sign-in {
  margin: 0 auto;
  max-width: 400px;
}

.nty-tf-sign-in {
  height: 48px;
  width: stretch;
}
.nty-tf-sign-in .mdc-floating-label--float-above {
  transform: translateY(-33.25px) scale(1);
}
.nty-tf-sign-in .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.nty-tf-sign-in.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-sign-in .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-30.75px) scale(0.75);
}
.nty-tf-sign-in.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-sign-in .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.nty-tf-sign-in .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-48px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-48px {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
}

.mdc-button.nty-button-submit-sign-in {
  margin: 8px 0 24px;
  width: stretch;
}

.nty-container-sign-up {
  margin: 0 auto;
  max-width: 400px;
}

.nty-tf-sign-up {
  height: 48px;
  width: stretch;
}
.nty-tf-sign-up .mdc-floating-label--float-above {
  transform: translateY(-33.25px) scale(1);
}
.nty-tf-sign-up .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.nty-tf-sign-up.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-sign-up .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-30.75px) scale(0.75);
}
.nty-tf-sign-up.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.nty-tf-sign-up .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.nty-tf-sign-up .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-48px 250ms 1;
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-48px {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-30.75px) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-30.75px) scale(0.75);
  }
}

.mdc-button.nty-button-submit-sign-up {
  margin: 8px 0 24px;
  width: stretch;
}

.mdc-snackbar {
  z-index: 8;
  margin: 8px;
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-snackbar__surface {
  background-color: #333333;
}

.mdc-snackbar__label {
  color: rgba(255, 255, 255, 0.87);
}

.mdc-snackbar__surface {
  min-width: 344px;
}
@media (max-width: 480px), (max-width: 344px) {
  .mdc-snackbar__surface {
    min-width: 100%;
  }
}

.mdc-snackbar__surface {
  max-width: 672px;
}

.mdc-snackbar__surface {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-snackbar__surface {
  border-radius: 4px;
  /* @alternate */
  border-radius: var(--mdc-shape-small, 4px);
}

.mdc-snackbar--opening,
.mdc-snackbar--open,
.mdc-snackbar--closing {
  display: flex;
}

.mdc-snackbar--open .mdc-snackbar__label,
.mdc-snackbar--open .mdc-snackbar__actions {
  visibility: visible;
}

.mdc-snackbar--leading {
  justify-content: flex-start;
}

.mdc-snackbar--stacked .mdc-snackbar__label {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 8px;
  padding-bottom: 12px;
}
[dir=rtl] .mdc-snackbar--stacked .mdc-snackbar__label, .mdc-snackbar--stacked .mdc-snackbar__label[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-snackbar--stacked .mdc-snackbar__surface {
  flex-direction: column;
  align-items: flex-start;
}
.mdc-snackbar--stacked .mdc-snackbar__actions {
  align-self: flex-end;
  margin-bottom: 8px;
}

.mdc-snackbar__surface {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  transform: scale(0.8);
  opacity: 0;
}
.mdc-snackbar__surface::before {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 1px solid transparent;
  border-radius: inherit;
  content: "";
}
[dir=rtl] .mdc-snackbar__surface, .mdc-snackbar__surface[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}
.mdc-snackbar--open .mdc-snackbar__surface {
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
  transition: opacity 150ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-snackbar--closing .mdc-snackbar__surface {
  transform: scale(1);
  transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-snackbar__label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Roboto,-apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-body2-font-size, 0.875rem);
  line-height: 1.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-body2-line-height, 1.25rem);
  font-weight: 400;
  /* @alternate */
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: 0.0178571429em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-body2-letter-spacing, 0.0178571429em);
  text-decoration: inherit;
  /* @alternate */
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: inherit;
  /* @alternate */
  text-transform: var(--mdc-typography-body2-text-transform, inherit);
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 8px;
  width: 100%;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  visibility: hidden;
  padding-top: 14px;
  padding-bottom: 14px;
}
[dir=rtl] .mdc-snackbar__label, .mdc-snackbar__label[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 16px;
}

.mdc-snackbar__label::before {
  display: inline;
  content: attr(data-mdc-snackbar-label-text);
}

.mdc-snackbar__actions {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  box-sizing: border-box;
  visibility: hidden;
}

.mdc-snackbar__action:not(:disabled) {
  color: #bb86fc;
}
.mdc-snackbar__action::before, .mdc-snackbar__action::after {
  background-color: #bb86fc;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #bb86fc);
}
.mdc-snackbar__action:hover::before, .mdc-snackbar__action.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-snackbar__action.mdc-ripple-upgraded--background-focused::before, .mdc-snackbar__action:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-snackbar__action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-snackbar__action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-snackbar__action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-snackbar__dismiss {
  color: rgba(255, 255, 255, 0.87);
}
.mdc-snackbar__dismiss::before, .mdc-snackbar__dismiss::after {
  background-color: rgba(255, 255, 255, 0.87);
  /* @alternate */
  background-color: var(--mdc-ripple-color, rgba(255, 255, 255, 0.87));
}
.mdc-snackbar__dismiss:hover::before, .mdc-snackbar__dismiss.mdc-ripple-surface--hover::before {
  opacity: 0.08;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.08);
}
.mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused::before, .mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus::before {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.24);
}
.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.24);
}
.mdc-snackbar__dismiss.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.24);
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss {
  width: 36px;
  height: 36px;
  padding: 9px;
  font-size: 18px;
}
.mdc-snackbar__dismiss.mdc-snackbar__dismiss svg,
.mdc-snackbar__dismiss.mdc-snackbar__dismiss img {
  width: 18px;
  height: 18px;
}

.mdc-snackbar__action + .mdc-snackbar__dismiss {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-snackbar__action + .mdc-snackbar__dismiss, .mdc-snackbar__action + .mdc-snackbar__dismiss[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

.nty-snackbar .mdc-snackbar__surface {
  background-color: #2f4456;
}

.mdc-button.nty-button-snackbar:not(:disabled) {
  color: #fff;
}
.nty-container-tags {
  margin: 24px auto 0;
  max-width: 600px;
}

.mdc-menu.nty-menu-tag {
  width: 148px;
}
.mdc-menu.nty-menu-tag .mdc-list-item {
  height: 40px;
}

.mdc-list.nty-list-tags .mdc-list-item {
  height: 32px;
}

.nty-menu-tag-toggler {
  width: 28px;
  height: 28px;
  padding: 2px;
}

.nty-container-trash-controls {
  margin: 6px 0 8px;
  text-align: center;
}

.mdc-list.nty-list-trash {
  padding: 0;
}
.mdc-list.nty-list-trash .mdc-list-item.nty-list-trash-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mdc-list.nty-list-trash-controls {
  padding: 0;
}